import { gql, useMutation } from '@apollo/client'

export const MUTATION = gql`
  mutation CreatePublicationMonitoringMutation($input: CreatePublicationMonitoringInput!) {
    createPublicationMonitoring(input: $input) {
      clientMutationId
    }
  }
`

export function useCreatePublicationMonitoringMutation(topicId: number, title: string) {
  const [createPublicationMonitoring] = useMutation(MUTATION)
  const variables = {
    input: {
      idExternalReference: topicId,
      monitoringName: title,
      monitoringType: 'PERSON',
      shouldFailWhenOabMonitoredAlreadyExists: false,
      shouldMonitorLawsuits: false,
      payload: {
        frequencyNotifications: 'IMMEDIATELY',
        rotulos: [],
        sources: [],
        status: 'ATIVO',
        value: title,
      },
    },
  }

  return () => createPublicationMonitoring({ variables })
}
