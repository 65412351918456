import { FormatterUnit } from '../../types/formatter.type'

const smallUnits: FormatterUnit[] = ['seconds', 'minutes', 'hours', 'days']

export const round = (duration: number, unit: FormatterUnit): number => {
  if (smallUnits.includes(unit)) {
    return Math.trunc(duration)
  } else {
    return Math.round(duration)
  }
}
