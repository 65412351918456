import { BodyText, Button, Heading, Modal } from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'

import errorImgSrc from '../../public/error.svg'
import styles from './search-by-cpf-not-found.module.scss'

interface SearchByCpfNotFoundProps {
  handleClose: () => void
}

export function SearchByCpfNotFound(props: SearchByCpfNotFoundProps) {
  const { handleClose } = props

  return (
    <>
      <Modal.IconClose data-testid="lawsuit-names-search-by-cpf-close" />
      <Modal.Body>
        <div className={styles.container}>
          <Image
            alt="Não foram encontrados processos vinculados a esse CPF"
            src={errorImgSrc}
            height={200}
            width={200}
            priority
            className={styles.image}
          />

          <Heading className={styles.title} size="md">
            Não foram encontrados processos vinculados a esse CPF
          </Heading>
          <BodyText className={styles.description} size="md">
            Pode não ter sido viável identificar o CPF no processo que você está buscando. Busque
            entre todos os processos que conseguimos encontrar para o nome.
          </BodyText>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleClose()} kind="primary">
          Fechar
        </Button>
      </Modal.Footer>
    </>
  )
}
