import { gql, useLazyQuery } from '@apollo/client'
import { Modal } from '@farol-ds/react'
import { SearchIntentionOptionEnum } from '@jusbrasil-web/lawsuit/search'
import { Nullable } from '@jusbrasil-web/lawsuit/shared'
import { COMPONENT_CLASS, storeComponentOriginForNextPage } from '@jusbrasil-web/shared-ga4-events'
import { useEffect, useState } from 'react'

import { useContextData } from '../context-provider'
import {
  SearchByCpfQuery,
  SearchByCpfQueryVariables,
} from './__generated__/search-by-cpf-modal.graphql'
import { SearchByCpfForm } from './search-by-cpf-form'
import { SearchByCpfNotFound } from './search-by-cpf-not-found'

export const SEARCH_BY_CPF_QUERY = gql`
  query searchByCpf($identificationType: String!, $identificationNumber: String!) {
    root {
      partyUrl: partyEntityUrl(
        identificationType: $identificationType
        identificationNumber: $identificationNumber
      )
      viewer: me {
        cpf
      }
    }
  }
`

const eventData = {
  from_component: 'SearchByCpfModal',
}
export function SearchByCpfModal() {
  const { setShowModalSearchByCpf, dispatchEvent } = useContextData()

  const variables: SearchByCpfQueryVariables = {
    identificationType: 'CPF',
    identificationNumber: '',
  }

  const [hasNotFound, setHasNotFound] = useState(false)

  const [fetchData, { data, error }] = useLazyQuery<SearchByCpfQuery>(SEARCH_BY_CPF_QUERY, {
    variables,
  })

  useEffect(() => {
    if (data?.root.partyUrl) {
      window.location.assign(data?.root.partyUrl)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setHasNotFound(true)
    }
  }, [error])

  const handleClose = () => {
    dispatchEvent('Topic.RefineSearchModalClosed', eventData)
    setShowModalSearchByCpf(false)
  }

  const saveSearchByCpfInteraction = (query: string, cpf: string | null | undefined) => {
    if (!cpf) {
      const relation = SearchIntentionOptionEnum.UNKNOWN
      const clearedQuery = query.replace(/[^\d]+/g, '')
      const queryId = Buffer.from(clearedQuery).toString('base64')
      const payload = { query: clearedQuery, queryId, relation }

      sessionStorage.setItem('saveSearchInteraction', JSON.stringify(payload))
    }
  }

  const handleClickSearch = async (unmaskedCpf: string, maskedCpf: string) => {
    const result = await fetchData({ variables: { identificationNumber: unmaskedCpf } })
    const partyUrl = result?.data?.root.partyUrl
    const cpf = result?.data?.root?.viewer?.cpf
    saveSearchByCpfInteraction(unmaskedCpf, cpf)

    storeComponentOriginForNextPage({
      componentClass: COMPONENT_CLASS.PERSON_SEARCH_PANEL,
      componentName: null,
      searchTerm: maskedCpf,
      searchMethod: 'person_search',
    })

    sendMetricsViewed(unmaskedCpf, maskedCpf, partyUrl)
  }

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      handleClose()
    }
  }

  const sendMetricsViewed = (
    unmaskedCpf: string,
    maskedCpf: string,
    partyUrl: Nullable<string>
  ) => {
    const entityId = partyUrl ? partyUrl.match(/cpf-(.*)/)?.[1] : ''
    const document = entityId ? [`LAWSUIT_PARTY_ENTITY-${entityId}`] : []

    dispatchEvent('Search.Viewed', {
      ...eventData,
      query: maskedCpf,
      sanitized_query: unmaskedCpf,
      hasResult: true,
      section: 'consulta-processual',
      documents: document,
    })
  }

  useEffect(() => {
    dispatchEvent('Topic.RefineSearchModalViewed', eventData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal open onOpenChange={handleOpenChange} size="sm">
      <Modal.Content data-testid="search-by-cpf-modal">
        {!hasNotFound ? (
          <SearchByCpfForm handleClickSearch={handleClickSearch} />
        ) : (
          <SearchByCpfNotFound handleClose={handleClose} />
        )}
      </Modal.Content>
    </Modal>
  )
}
