import { abbreviatedPersonNameRegExp, pluralize } from '@jusbrasil-web/shared-utils-format'

import { buildLawsuitCount } from './build-lawsuit-count'

const BASE_URL = 'https://www.jusbrasil.com.br'
const getTopicProps = (name: string, url: string) => {
  if (url) {
    return { href: url }
  }

  const isAbbreviated = abbreviatedPersonNameRegExp.test(name)
  const relation = isAbbreviated ? 'nofollow' : ''
  return {
    href: `${BASE_URL}/busca?q=${encodeURIComponent(name).replace(/%20/g, '+')}`,
    rel: relation,
  }
}

function buildNameText(name: string, url: string) {
  return (
    <strong aria-label="Nome" {...getTopicProps(name, url)}>
      {name}
    </strong>
  )
}

export const buildMention = (
  topicName: string,
  topicUrl: string,
  displayedLawsuitCount: number
) => {
  const nameText = buildNameText(topicName, topicUrl)
  if (displayedLawsuitCount === 0) {
    return (
      <>
        O Jusbrasil
        <strong> não encontrou processos </strong>
        que mencionam o nome {nameText}
      </>
    )
  }

  const mention = pluralize(displayedLawsuitCount, 'menciona', 'mencionam')
  const lawsuitCountText = buildLawsuitCount(displayedLawsuitCount)
  return (
    <>
      O Jusbrasil encontrou {lawsuitCountText} que {mention} {nameText}, e que podem ser de pessoas
      diferentes com esse nome. Participar de um processo não significa ter cometido delito, a
      pessoa pode ser autora, ré, advogada, juíza, etc
    </>
  )
}
