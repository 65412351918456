import { BodyText, Heading } from '@farol-ds/react'

import { NameRootQuery } from '../../pages/name/__generated__/name.graphql'
import { LawsuitPartyEntity, PersonEntityCard } from '../person-entity-card'
import styles from './single-person-entity-wrapper.module.scss'

export interface SinglePersonEntityWrapperProps {
  topic: NonNullable<NameRootQuery['root']['topic']>
  lawsuitPartyEntity: LawsuitPartyEntity
  displayedLawsuitCount: number
}

export function SinglePersonEntityWrapper(props: SinglePersonEntityWrapperProps) {
  const { lawsuitPartyEntity } = props

  return (
    <>
      <Heading size="md" asChild>
        <h2>1 pessoa identificada</h2>
      </Heading>
      <BodyText size="md">
        Selecione a pessoa e verifique se existem processos pelo CPF dela.
      </BodyText>
      <PersonEntityCard
        classname={styles.personEntityCard}
        partyEntity={lawsuitPartyEntity}
        fromComponent="SinglePersonEntityWidget"
      />
    </>
  )
}
