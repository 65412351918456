import { gql, useMutation } from '@apollo/client'

export const MUTATION = gql`
  mutation DeletePublicationMonitoringMutation($input: DeletePublicationMonitoringInput!) {
    deletePublicationMonitoring(input: $input) {
      clientMutationId
    }
  }
`

export function useDeletePublicationMonitoringMutation(topicId: number) {
  const [deletePublicationMonitoring] = useMutation(MUTATION)
  const variables = {
    input: {
      topicId,
    },
  }
  return () => deletePublicationMonitoring({ variables })
}
