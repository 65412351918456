import { useEffect, useRef, useState } from 'react'

export type ScrollDirection = 'up' | 'down' | undefined

export function useScrollDirection(): ScrollDirection {
  const prevScrollX = useRef(0)
  const [state, setState] = useState<ScrollDirection>()

  useEffect(() => {
    function listener() {
      const bodyOffset = document.body.getBoundingClientRect()
      const direction = prevScrollX.current > -bodyOffset.top ? 'down' : 'up'
      if (state !== direction) {
        setState(direction)
      }
      prevScrollX.current = -bodyOffset.top
    }

    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [state])

  return state
}
