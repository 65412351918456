export function buildLawsuitStates(lawsuitStates: string[]) {
  const limit = 3
  const amountOverLimit = lawsuitStates.length - limit
  let states = lawsuitStates

  if (amountOverLimit > 0) {
    states = lawsuitStates.slice(0, 3)
    states.push(`+${amountOverLimit}`)
  }

  return states.join(', ').replace(/, ([^,]*)$/, ' e $1')
}
