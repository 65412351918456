import classNames from 'classnames'

import { BifrostModalTriggerOptions } from '../../types'
import styles from './login-trigger.module.scss'

export interface LoginRequiredTriggerProps {
  children: React.ReactNode
  className?: string
  options?: BifrostModalTriggerOptions
  isLogged?: boolean
  onOpen?: () => void
}

export function LoginTrigger(props: LoginRequiredTriggerProps) {
  const { className, children, options, isLogged, onOpen } = props

  const requireLogin = () => {
    if (window.Bifrost) {
      window.Bifrost.open(options)
      onOpen?.()
      return
    }

    if (window.SignUpModal) {
      window.SignUpModal.openModal(options)
      return
    }

    setTimeout(requireLogin, 100)
  }
  /* [TODO] change to accept all types of callback not only onClick. @anaclaramsb */
  return !isLogged ? (
    <div
      role="button"
      tabIndex={0}
      className={classNames(styles.root, className)}
      onClick={(evt) => {
        evt.preventDefault()
        requireLogin()
      }}
      onKeyDown={(evt) => {
        evt.preventDefault()
        if (evt.key === 'Enter' || evt.key === ' ') {
          requireLogin()
        }
      }}
    >
      {children}
    </div>
  ) : (
    children
  )
}
