import classNames from 'classnames'
import { KeyboardEvent, MouseEvent } from 'react'

import copyValue from '../../utils/copy-value'
import styles from './debug-info.module.scss'

function handleKeydownEvent(
  event: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>,
  callback: (event: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>) => void,
  targetKeyCode = 13
) {
  const { keyCode } = event as KeyboardEvent<HTMLSpanElement>

  if (keyCode === targetKeyCode) {
    callback(event)
  }
}

interface FieldInfoProps {
  label: string
  value: string | number | boolean | null
}
export function DebugInfoFieldInfo({ label, value }: FieldInfoProps) {
  return (
    <div className={classNames(styles.debugInfo, styles.field)} key={label}>
      <span className={classNames(styles.debugInfo, styles.field, styles.label)}>{label}: </span>
      <span
        className={classNames(styles.debugInfo, styles.field, styles.value)}
        onClick={(evt: MouseEvent<HTMLSpanElement>) => copyValue(evt)}
        role="button"
        tabIndex={0}
        title="Clique para copiar o valor"
        onKeyDown={(evt) => handleKeydownEvent(evt, copyValue)}
      >
        {`${value}`}
      </span>
    </div>
  )
}
