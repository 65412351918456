import { BodyText, Subtitle } from '@farol-ds/react'
import { ABTestParticipateResponse } from '@jusbrasil-web/shared-ab-test'
import classNames from 'classnames'

import styles from './debug-info.module.scss'
import { DebugInfoFieldInfo } from './debug-info-field-info'

interface DebugInfoExperimentsProps {
  experiments: ABTestParticipateResponse[]
}

export function DebugInfoExperiments(props: DebugInfoExperimentsProps) {
  const { experiments } = props
  const hasExperiments = experiments?.length > 0

  return (
    <>
      <div className={classNames(styles.debugInfo, styles.titleExperiments)}>
        <Subtitle size="md">Experimentos</Subtitle>
      </div>

      {hasExperiments ? (
        experiments.map((item, idx) => {
          return (
            <div key={idx}>
              <DebugInfoFieldInfo label="Experimento" value={item.experiment} />
              <DebugInfoFieldInfo label="Variante" value={item.alternative} />
              <DebugInfoFieldInfo label="Participando" value={!!item.participating} />
            </div>
          )
        })
      ) : (
        <BodyText>Nenhum experimento ativo</BodyText>
      )}
    </>
  )
}
