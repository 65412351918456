import { useEffect } from 'react'

export type UseOnScrollProps = {
  delay?: number
  callback?: (scrollY: number) => void
}

export function useOnScroll(props: UseOnScrollProps = {}) {
  const { delay = 500, callback } = props
  let timeoutId: NodeJS.Timeout

  const onScroll = () => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      callback?.(window.scrollY)
    }, delay)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])
}
