import { gql } from '@apollo/client'

import { BuildDescription_ContactFragment } from './__generated__/build-description.graphql'
import { buildMention } from './build-mention'

export interface BuildDescriptionProps {
  contact: BuildDescription_ContactFragment | null
  topicName: string
  displayedLawsuitCount: number
  isEntitiesWidgetExperimentEnabled?: boolean
}

export function buildDescription(props: BuildDescriptionProps) {
  const { contact, topicName, displayedLawsuitCount, isEntitiesWidgetExperimentEnabled } = props
  const topicUrl = contact?.url || ''
  const mentionText = buildMention(topicName, topicUrl, displayedLawsuitCount)

  const totalCanonicalEntities = contact?.totalCanonicalEntities || 0
  if (
    totalCanonicalEntities === 1 ||
    (isEntitiesWidgetExperimentEnabled && totalCanonicalEntities > 1)
  )
    return <>Encontre a pessoa que você está procurando e confira todos os processos.</>

  return <>{mentionText}.</>
}

buildDescription.fragments = {
  contact: gql`
    fragment BuildDescription_contact on Contact {
      totalCanonicalEntities
      url
    }
  `,
}
