import { Confirm } from '@farol-ds/react'
import { ReactNode } from 'react'

import { useNameMonitoringContext } from './name-monitoring-context'
import styles from './name-monitoring-result-alert.module.scss'
import { NameMonitoringActionState, NameMonitoringActionStatus, NameMonitoringError } from './types'

type AlertConfig = {
  title: string
  description: string | ReactNode
  confirmLabel: string
  cancelLabel?: string
  onConfirm?: () => void
} | null

const SUPPORT_URL =
  'https://suporte.jusbrasil.com.br/hc/pt-br/articles/4402328034836-Como-proceder-em-caso-de-erro-ao-acompanhar-um-nome'

const TOO_BROAD_CONFIG: AlertConfig = {
  title: 'Acompanhamento não pôde ser ativado',
  description:
    'A configuração escolhida retornou um alto volume de publicações. Para otimizar sua experiência, opte por acompanhar processos específicos desse nome.',
  confirmLabel: 'Saiba mais',
  cancelLabel: 'Fechar',
  onConfirm: () => window.open(SUPPORT_URL, '_blank'),
}

const ERROR_CONFIG: AlertConfig = {
  title: 'Acompanhamento não pôde ser ativado',
  description:
    'O acompanhamento não pôde ser iniciado. Tente recarregar a página ou tente novamente mais tarde.',
  confirmLabel: 'Recarregar página',
  cancelLabel: 'Fechar',
  onConfirm: () => window.location.reload(),
}

const LIMIT_REACHED_CONFIG: AlertConfig = {
  title: 'Você atingiu o limite de acompanhamentos',
  description:
    'O seu plano atual não permite o acompanhamento de novos nomes. Você pode deixar de acompanhar nomes antigos para criar um novo acompanhamento.',
  confirmLabel: 'Fechar',
  cancelLabel: '',
  onConfirm: () => false,
}

interface NameMonitoringResultAlertProps {
  onClose: () => void
}

export function NameMonitoringResultAlert(props: NameMonitoringResultAlertProps) {
  const { onClose } = props
  const { monitoringActionState } = useNameMonitoringContext()

  const isOpen =
    monitoringActionState !== null &&
    monitoringActionState.status !== NameMonitoringActionStatus.LOADING
  if (!isOpen) return null

  const SUCCESS_CONFIG: AlertConfig = {
    title: 'Acompanhamento ativado',
    description: (
      <>
        Você será notificado por <strong>e-mail</strong> sempre que este nome for citado em
        processos encontrados pelo Jusbrasil.
      </>
    ),
    confirmLabel: 'Ok',
  }

  function getAlertConfig(monitoringActionState: NameMonitoringActionState) {
    if (monitoringActionState.status === NameMonitoringActionStatus.SUCCESS) {
      return SUCCESS_CONFIG
    }

    if (monitoringActionState.errorType === NameMonitoringError.TOO_BROAD) {
      return TOO_BROAD_CONFIG
    }

    if (monitoringActionState.errorType === NameMonitoringError.LIMIT_REACHED) {
      return LIMIT_REACHED_CONFIG
    }

    return ERROR_CONFIG
  }

  const config = getAlertConfig(monitoringActionState)
  if (!config) return null

  const { title, description, confirmLabel, cancelLabel, onConfirm } = config

  const handleConfirm = () => {
    if (onConfirm) onConfirm()
    onClose()
  }

  return (
    <Confirm
      open
      cancelProps={{ className: !cancelLabel ? styles.hideButton : '' }}
      cancelLabel={cancelLabel}
      confirmLabel={confirmLabel}
      description={description}
      title={title}
      onConfirm={handleConfirm}
      onCancel={onClose}
      onEscapeKeyDown={onClose}
    />
  )
}
