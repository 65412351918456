import { gql } from '@apollo/client'
import { BodyText, Button, Container, Heading, IconButton, InformationIcon } from '@farol-ds/react'
import { truncateSelectedContent, useExperiments } from '@jusbrasil-web/lawsuit/shared'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import { COMPONENT_CLASS, GA4ClickEvent, SELECTED_OPTION } from '@jusbrasil-web/shared-ga4-events'
import { useOnScreen } from '@jusbrasil-web/shared-hooks'
import classNames from 'classnames'
import { useRef, useState } from 'react'

import { EXPERIMENTS } from '../../utils/experiments'
import { useContextData } from '../context-provider'
import { NameMonitoringButton, NameMonitoringContextProvider } from '../name-monitoring'
import {
  Header_ContactFragment,
  Header_TopicFragment,
  Header_ViewerProfileFragment,
} from './__generated__/header.graphql'
import styles from './header.module.scss'
import { buildDescription, misinformationModal } from './utils'

export interface HeaderProps {
  topic: Header_TopicFragment
  contact: Header_ContactFragment | null
  viewer: Header_ViewerProfileFragment | null
  displayedLawsuitCount: number
}

const fromComponent = 'Header'

export function Header(props: HeaderProps) {
  const { getExperiment } = useExperiments()
  const experimentEntitiesWidget = getExperiment(EXPERIMENTS.ENTITIES_WIDGET)
  const isEntitiesWidgetExperimentEnabled = experimentEntitiesWidget?.participating

  const { topic, contact, viewer, displayedLawsuitCount } = props
  const topicName = contact?.name || topic.title || ''
  const description = buildDescription({
    contact,
    topicName,
    displayedLawsuitCount,
    isEntitiesWidgetExperimentEnabled,
  })
  const { dispatchEvent } = useContextData()
  const { sendGA4Event } = useGA4Dispatcher()

  const { setShowModalSearchByCpf } = useContextData()
  const [misinformationModalOpen, setMisinformationModalOpen] = useState(false)

  const searchByCpfBtnRef = useRef<HTMLDivElement>(null)
  const isActionButtons = useOnScreen(searchByCpfBtnRef, { threshold: 0 }, true)

  const fixedActionsButtonClassNames = classNames(styles.actionsButtonWrapper, {
    [styles.fixedActionsButtonWrapper]: !isActionButtons,
    [styles.containerPadding]: isActionButtons,
  })

  const handleClickShowModalSearchByCpf = () => {
    dispatchEvent('Topic.RefineSearchClicked', { from_component: fromComponent })

    setShowModalSearchByCpf(true)
    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.NAME_PAGE_HEADER,
        componentName: null,
        selectedOption: SELECTED_OPTION.ENTITY_SEARCH_BUTTON,
      })
    )
  }

  const handleCopyContent = async () => {
    dispatchEvent('Topic.CopyContent', {
      from_component: fromComponent,
      metadata: {
        content: truncateSelectedContent(),
      },
    })
  }

  const onMisinformationModalOpenChange = (open: boolean) => {
    if (open) {
      dispatchEvent('Topic.OpenMisinformationDisclaimerModal')
    }

    setMisinformationModalOpen(open)
  }

  return (
    <NameMonitoringContextProvider topic={topic} viewer={viewer}>
      <header className={styles.container} onCopy={handleCopyContent}>
        <Heading data-testid="header-name" className={styles.name} size="xl" asChild>
          <h1>{topicName}</h1>
        </Heading>
        <BodyText
          className={styles.description}
          data-testid="header-description"
          id="header-description"
        >
          {description}
        </BodyText>
        <div className={styles.actions}>
          <div className={styles.actionsMain} ref={searchByCpfBtnRef}>
            <div className={fixedActionsButtonClassNames}>
              <Container className={styles.actionsContainer}>
                <Button
                  kind="primary"
                  className={styles.fixedActionsButtonMobile}
                  data-testid="search-by-cpf-button"
                  onClick={handleClickShowModalSearchByCpf}
                >
                  Buscar por CPF
                </Button>
                <NameMonitoringButton
                  className={styles.actionsMonitoringBtn}
                  variant="desktop"
                  viewer={viewer}
                />
              </Container>
            </div>
          </div>
          <div className={styles.iconButtons}>
            <NameMonitoringButton
              className={styles.actionsMonitoringBtnMobile}
              variant="mobile"
              viewer={viewer}
            />
            <IconButton
              onClick={() => onMisinformationModalOpenChange(true)}
              data-testid="misinformation-button"
              icon={<InformationIcon />}
              kind="plain"
              label="Informações"
            />
          </div>
        </div>
      </header>
      {misinformationModalOpen && misinformationModal(setMisinformationModalOpen)}
    </NameMonitoringContextProvider>
  )
}

Header.fragments = {
  topic: gql`
    fragment Header_topic on Topic {
      title
      ...NameMonitoringContextProvider_topic
    }

    ${NameMonitoringContextProvider.fragments.topic}
  `,
  contact: gql`
    fragment Header_contact on Contact {
      name
      ...BuildDescription_contact
    }

    ${buildDescription.fragments.contact}
  `,
  viewer: gql`
    fragment Header_viewerProfile on Profile {
      ...NameMonitoringContextProvider_viewer
      ...NameMonitoringButton_viewer
    }

    ${NameMonitoringContextProvider.fragments.viewer}
    ${NameMonitoringButton.fragments.viewer}
  `,
}
