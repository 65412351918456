import { gql } from '@apollo/client'
import { Nullable } from '@jusbrasil-web/lawsuit/shared'

import {
  DebugInfoGeneral_TopicFragment,
  DebugInfoGeneral_ViewerFragment,
} from './__generated__/debug-info-general.graphql'
import { DebugInfoFieldInfo } from './debug-info-field-info'

interface DebugInfoGeneralProps {
  topic: NonNullable<DebugInfoGeneral_TopicFragment>
  viewer: Nullable<DebugInfoGeneral_ViewerFragment>
}

export function DebugInfoGeneral(props: DebugInfoGeneralProps) {
  const { topic, viewer } = props
  const { tid, topicType, detectedEntityType, origin, status, visibility, contact } = topic

  const isAdmin = viewer?.user?.isAdmin

  return (
    <>
      {isAdmin && (
        <>
          <DebugInfoFieldInfo label="ID" value={tid} />

          {contact && (
            <>
              <DebugInfoFieldInfo
                label="LawsuitParty ID"
                value={contact ? contact.contactId : 'null'}
              />
            </>
          )}
          <DebugInfoFieldInfo label="Status" value={status} />
          <DebugInfoFieldInfo label="Tipo Tópico" value={topicType} />
          <DebugInfoFieldInfo label="Tipo Entidade" value={detectedEntityType} />
          <DebugInfoFieldInfo label="Origem" value={origin} />
          <DebugInfoFieldInfo label="Visibilidade" value={visibility} />
        </>
      )}
    </>
  )
}

DebugInfoGeneral.fragments = {
  topic: gql`
    fragment DebugInfoGeneral_topic on Topic {
      tid
      topicType
      status
      visibility
      detectedEntityType
      origin
      contact {
        contactId
      }
    }
  `,

  viewer: gql`
    fragment DebugInfoGeneral_viewer on Profile {
      user {
        isAdmin
      }
    }
  `,
}
