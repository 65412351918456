import { gql } from '@apollo/client'
import {
  Accordion,
  BodyText,
  Button,
  Card,
  ChevronDownIcon,
  Heading,
  Link,
  List,
  Spinner,
} from '@farol-ds/react'
import { useExperiments, usePagination } from '@jusbrasil-web/lawsuit/shared'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'
import { LoginTrigger } from '@jusbrasil-web/shared-bifrost'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import {
  COMPONENT_CLASS,
  COMPONENT_NAME,
  GA4ClickEvent,
  GA4ViewItemListEvent,
  ITEM_LIST_COMPONENT_RENDERED_CLASS,
  ITEM_LIST_COMPONENT_RENDERED_NAME,
  SELECTED_OPTION,
} from '@jusbrasil-web/shared-ga4-events'
import { handleTriggerExecution } from '@jusbrasil-web/shared-utils-url'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { APP_ID, PERSONS_LIST_LIMIT } from '../../utils'
import { EXPERIMENTS } from '../../utils/experiments'
import { useContextData } from '../context-provider'
import {
  ListRelatedPersonsWidgetFragment,
  RelatedPersonsListQuery,
  RelatedPersonsListQueryVariables,
  RelatedPersonWidget_ContactFragment,
} from './__generated__/related-person-widget.graphql'
import styles from './related-person-widget.module.scss'

const FRAGMENT_RELATED_PERSON = gql`
  fragment ListRelatedPersonsWidget on ContactRelatedPersonsWithMostOccurrencesConnection {
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        id
        name
        url
        contactId
      }
    }
  }
`
RelatedPersonWidget.fragments = {
  contact: gql`
    fragment RelatedPersonWidget_contact on Contact {
      contactId
      id
      url
      lawsuitPartyEntities(first: 10, source: "haystack") {
        total
      }
      relatedPersonsWithMostOccurrences(first: $personsListLimit, after: $after) {
        ...ListRelatedPersonsWidget
      }
    }

    ${FRAGMENT_RELATED_PERSON}
  `,
}

export const RELATED_PERSONS_QUERY = gql`
  query relatedPersonsList($personsListLimit: Int!, $contactId: String!, $after: String) {
    root {
      contact(contactId: $contactId) {
        contactId
        id
        relatedPersonsWithMostOccurrences(first: $personsListLimit, after: $after) {
          ...ListRelatedPersonsWidget
        }
      }
    }
  }

  ${FRAGMENT_RELATED_PERSON}
`
interface RelatedPersonWidgetProps {
  contact: RelatedPersonWidget_ContactFragment | null
  name: string
  truncateName?: boolean
}

type LawsuitNode = NonNullable<
  NonNullable<NonNullable<ListRelatedPersonsWidgetFragment['edges']>[number]>['node']
>

type RelatedPersonsWithMostOccurrencesNode = NonNullable<
  NonNullable<
    NonNullable<RelatedPersonWidget_ContactFragment['relatedPersonsWithMostOccurrences']>['edges']
  >[number]
>['node']

export function RelatedPersonWidget(props: RelatedPersonWidgetProps) {
  const { dispatchEvent, isGoogleBotAgent, isLogged } = useContextData()
  const { sendGA4Event } = useGA4Dispatcher()

  const { contact, name, truncateName = false } = props

  const cursor = contact?.relatedPersonsWithMostOccurrences?.pageInfo?.endCursor
  const [defaultAccordionValue, setDefaultAccordionValue] = useState<string | undefined>(undefined)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [updatedRelatedPeopleWidgetList, setUpdatedRelatedPeopleWidgetList] = useState<
    RelatedPersonsWithMostOccurrencesNode[]
  >([])

  const variables: RelatedPersonsListQueryVariables = {
    contactId: contact?.contactId || '',
    personsListLimit: PERSONS_LIST_LIMIT,
    after: cursor ?? null,
  }

  const { loading, data, hasNextPage, handleLoadMore } = usePagination<
    RelatedPersonsListQuery,
    RelatedPersonsListQueryVariables
  >(
    RELATED_PERSONS_QUERY,
    variables,
    contact?.relatedPersonsWithMostOccurrences?.pageInfo,
    'root.contact.relatedPersonsWithMostOccurrences.pageInfo'
  )

  const connection =
    data?.root.contact?.relatedPersonsWithMostOccurrences ||
    contact?.relatedPersonsWithMostOccurrences

  const relatedPersonEdges = nullableConnectionNodes(connection)

  const handleClickedRelatedPerson = (
    contactId?: RelatedPersonWidget_ContactFragment['contactId']
  ) => {
    const eventData = {
      artifact: 'processos/nome',
      page: 'nome',
      pageLocal: 'tag_list',
    }
    dispatchEvent('Topic.LinkClicked', eventData)
    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.RELATED_NAMES_ACCORDION,
        componentName: COMPONENT_NAME.RELATED_NAMES_LIST,
        selectedOption: SELECTED_OPTION.NAME_LINK,
        resourceId: contactId || null,
      })
    )
  }

  const handleLoadMoreClick = () => {
    handleLoadMore()
    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.RELATED_NAMES_ACCORDION,
        componentName: COMPONENT_NAME.RELATED_NAMES_LIST,
        selectedOption: SELECTED_OPTION.SHOW_MORE,
      })
    )
  }

  const loginOptions = {
    nextUrl: `${contact?.url}?pessoasRelacionadas=true`,
    eventData: {
      app_id: APP_ID,
      feature_code: 'unlock_lawsuit_list',
      from_component: 'LawsuitList',
    },
  }

  const { getExperiment } = useExperiments()
  const experimentEntitiesWidget = getExperiment(EXPERIMENTS.ENTITIES_WIDGET)
  const showAsAccordion =
    contact?.lawsuitPartyEntities?.total === 1 || experimentEntitiesWidget?.participating
  const showWhenAccordionOpen = showAsAccordion && isAccordionOpen

  const relatedPersonList = () => {
    return (
      <>
        <List unstyled>
          {relatedPersonEdges?.map((contact: LawsuitNode) => {
            return (
              <List.Item key={contact?.contactId}>
                <Link
                  className={classNames(styles.link, {
                    [styles.truncate]: truncateName,
                  })}
                  href={contact?.url || ''}
                  onClick={() => handleClickedRelatedPerson(contact?.contactId)}
                  type="tertiary"
                  data-testid="related-person-link"
                >
                  {contact?.name}
                </Link>
              </List.Item>
            )
          })}
        </List>

        {loading && (
          <div data-testid="related-person-loading" className={styles.spinner}>
            <Spinner />
          </div>
        )}

        {!loading && hasNextPage() && (
          <Button
            kind="plain"
            data-testid="related-person-fetch-more"
            onClick={handleLoadMoreClick}
            rightIcon={<ChevronDownIcon size="sm" />}
          >
            Mostrar mais
          </Button>
        )}
      </>
    )
  }

  const unloggedProps: { value?: string } = {}
  if (!isLogged) {
    if (isGoogleBotAgent) {
      unloggedProps.value = 'relatedPeopleList'
    } else {
      unloggedProps.value = 'closed'
    }
  }

  useEffect(() => {
    handleTriggerExecution({
      triggerName: 'pessoasRelacionadas',
      onCallback: () => {
        if (isLogged) setDefaultAccordionValue('relatedPeopleList')
      },
    })
  }, [isLogged])

  const onAccordionClick = () => {
    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.RELATED_NAMES_ACCORDION,
        componentName: null,
        selectedOption: isAccordionOpen ? SELECTED_OPTION.COLLAPSE : SELECTED_OPTION.EXPAND,
      })
    )
  }

  useEffect(() => {
    if (relatedPersonEdges.length && (showWhenAccordionOpen || !showAsAccordion)) {
      sendGA4ViewItemListEvent()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendGA4Event, showWhenAccordionOpen, showAsAccordion, relatedPersonEdges.length])

  const sendGA4ViewItemListEvent = () => {
    const relatedPersonList = getRelatedPersonList()

    sendGA4Event(
      new GA4ViewItemListEvent({
        componentClass: null,
        componentName: null,
        renders: relatedPersonList?.map((relatedPerson) => ({
          item_variant: 'name_link',
          item_brand: ITEM_LIST_COMPONENT_RENDERED_CLASS.RELATED_NAMES_ACCORDION,
          item_name: ITEM_LIST_COMPONENT_RENDERED_NAME.RELATED_NAMES_LIST,
          item_id: relatedPerson?.contactId ?? null,
        })),
      })
    )
  }

  const getRelatedPersonList = () => {
    setUpdatedRelatedPeopleWidgetList(relatedPersonEdges)
    return relatedPersonEdges.length > updatedRelatedPeopleWidgetList.length
      ? relatedPersonEdges.filter(
          (relatedPerson) =>
            !updatedRelatedPeopleWidgetList.some(
              (updatedList) => relatedPerson?.contactId === updatedList?.contactId
            )
        )
      : relatedPersonEdges
  }

  return showAsAccordion ? (
    <LoginTrigger options={loginOptions} isLogged={isLogged}>
      <Accordion
        key={defaultAccordionValue}
        className={styles.container}
        collapsible
        type="single"
        data-testid="related-people-accordion"
        defaultValue={defaultAccordionValue}
        onValueChange={(value) => {
          setIsAccordionOpen(value === 'relatedPeopleList')
        }}
        {...unloggedProps}
        asChild
      >
        <Accordion.Item value="relatedPeopleList">
          <Accordion.Header
            data-testid="related-people-accordion-header"
            onClick={onAccordionClick}
          >
            <Accordion.Title>
              <Heading className={styles.accordionTitle}>Nomes relacionados</Heading>
              <p className={styles.accordionSubtitle}>
                Esses são os nomes que mais aparecem em processos junto com o nome {name}
              </p>
            </Accordion.Title>
          </Accordion.Header>
          <Accordion.Content data-testid="ac-related-people-list">
            {relatedPersonList()}
          </Accordion.Content>
        </Accordion.Item>
      </Accordion>
    </LoginTrigger>
  ) : (
    <Card className={styles.container}>
      <Heading asChild>
        <h3>Nomes relacionados</h3>
      </Heading>
      <BodyText className={styles.subtitle} data-testid="related-person-description">
        Esses são os nomes que mais aparecem em processos junto com o nome <strong>{name}</strong>
      </BodyText>
      {relatedPersonList()}
    </Card>
  )
}
