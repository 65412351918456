import { gql, useQuery } from '@apollo/client'
import { Col, Container, Heading, Row } from '@farol-ds/react'
import { withTopbarProps } from '@jusbrasil/topbar'
import { withApollo } from '@jusbrasil-web/core-apollo'
import {
  ExperimentsProvider,
  fetchExperiments,
  isGoogleBotAgent as verifyGoogleBotAgent,
  isLitigantUser,
  LawsuitAppPageProps,
  LawsuitIntentionsModal,
  LawsuitOccupationModal,
  LawsuitTopbar,
  ONBOARDING_STEPS,
  useUserOnboarding,
} from '@jusbrasil-web/lawsuit/shared'
import { ABTestParticipateResponse } from '@jusbrasil-web/shared-ab-test'
import { ApolloClientOptions } from '@jusbrasil-web/shared-apollo-client'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'
import { BifrostModalTrigger } from '@jusbrasil-web/shared-bifrost'
import { createDwellTimeListener } from '@jusbrasil-web/shared-dwell-time'
import { GA4ViewItemListRenderProps } from '@jusbrasil-web/shared-ga4-events'
import { useGTM, useGTMDataLayerWithViewer } from '@jusbrasil-web/shared-gtm'
import { hotjarEvent } from '@jusbrasil-web/shared-hotjar-script'
import { artifactPathFromSerpURL } from '@jusbrasil-web/shared-search-artifact-path'
import { withUserSession } from '@jusbrasil-web/shared-user-session'
import { abbreviatedPersonNameRegExp } from '@jusbrasil-web/shared-utils-format'
import { getQueryFromReferrer, getSanitizedQuery } from '@jusbrasil-web/shared-utils-url'
import { GetServerSideProps } from 'next'
import { useEffect, useMemo, useState } from 'react'
import UAParser from 'ua-parser-js'

import { Header, PageHead } from '../../components'
import { ContextProvider } from '../../components/context-provider'
import { DebugInfo } from '../../components/debug-info'
import { EntitiesWidget } from '../../components/entities-widget'
import { LawsuitsAccordion } from '../../components/lawsuit-accordion/lawsuits-accordion'
import { LawsuitList } from '../../components/lawsuit-list'
import { RelatedPersonWidget } from '../../components/related-person-widget'
import { SinglePersonEntityWrapper } from '../../components/single-person-entity-wrapper'
import { ServerQueryParams } from '../../types'
import { HTTP_STATUS, LAWSUIT_LIST_LIMIT, PERSONS_LIST_LIMIT } from '../../utils'
import { getQueryParams, makeEventDispatcherInstance } from '../../utils/events'
import { EXPERIMENTS, EXPERIMENTS_CONFIG } from '../../utils/experiments'
import { NameRootQuery, NameRootQueryVariables } from './__generated__/name.graphql'
import styles from './name.module.scss'

export const PAGE_QUERY = gql`
  query nameRoot(
    $tid: Int!
    $lawsuitListLimit: Int!
    $personsListLimit: Int!
    $after: String
    $maskSeniorAge: Boolean
  ) {
    root {
      topic: topic(tid: $tid) {
        tid
        lawsuitAmount
        detectedEntityType
        isMonitoredByViewer
        title
        slug
        isRemoved
        ...LawsuitList_topic
        ...Header_topic
        ...PageHead_topic
        ...MakeEventDispatcherInstance_topic
        ...DebugInfo_topic
        ...useUserOnboarding_topic
        ...EntitiesWidget_topic
        ...LawsuitsAccordion_topic
        contact {
          name
          totalCanonicalEntities
          lawsuits(first: $lawsuitListLimit, after: $after) {
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
              }
            }
          }
          ...Header_contact
          ...PageHead_contact
          ...RelatedPersonWidget_contact
        }
      }

      viewer: me {
        pid
        hasPaidPlan
        user {
          isAdmin
          profiles {
            edges {
              node {
                id
              }
            }
          }
        }
        ...Header_viewerProfile
        ...useUserOnboarding_viewer
        ...MakeEventDispatcherInstance_viewer
        ...PageHead_viewer
        ...DebugInfo_viewer
        ...useGTMDataLayerWithViewer_viewer
        ...LawsuitTopbar_viewer
      }
    }
  }

  ${Header.fragments.topic}
  ${Header.fragments.contact}
  ${Header.fragments.viewer}
  ${LawsuitList.fragments.topic}
  ${RelatedPersonWidget.fragments.contact}
  ${PageHead.fragments.topic}
  ${PageHead.fragments.contact}
  ${PageHead.fragments.viewer}
  ${useUserOnboarding.fragments.viewer}
  ${makeEventDispatcherInstance.fragments.viewer}
  ${makeEventDispatcherInstance.fragments.topic}
  ${DebugInfo.fragments.topic}
  ${DebugInfo.fragments.viewer}
  ${useUserOnboarding.fragments.topic}
  ${useGTMDataLayerWithViewer.fragments.viewer}
  ${EntitiesWidget.fragments.topic}
  ${LawsuitsAccordion.fragments.topic}
  ${LawsuitTopbar.fragments.viewer}
`

const FROM_COMPONENT = 'NamePage'

export interface NamePageProps {
  variables: NameRootQueryVariables
  ssrQueryParams: ServerQueryParams
  experiments: ABTestParticipateResponse[]
  deviceType: string
  isMobile: boolean
  referrer: string
  currentUrl: string
  isGoogleBotAgent: boolean
  litigantUser: boolean
  seed: number
}

export function NamePage(props: NamePageProps) {
  const {
    ssrQueryParams,
    deviceType,
    isMobile,
    referrer,
    variables,
    experiments,
    isGoogleBotAgent,
    litigantUser,
    seed,
  } = props

  const query = getQueryFromReferrer(referrer)

  const { data } = useQuery<NameRootQuery>(PAGE_QUERY, { variables })

  const topic = data?.root?.topic as NonNullable<NameRootQuery['root']['topic']> | null
  const contact = topic?.contact as NonNullable<NameRootQuery['root']['topic']>['contact']
  const lawsuitPartyEntity = contact?.lawsuitPartyEntities?.edges?.[0]?.node
  const totalCanonicalEntities = contact?.totalCanonicalEntities || 0

  const viewer = data?.root.viewer as NameRootQuery['root']['viewer'] | null
  const isLogged = !!viewer?.pid
  const isEntitiesWidgetExperimentEnabled = experiments?.some(
    (exp) => exp.experiment === EXPERIMENTS.ENTITIES_WIDGET && exp.participating
  )

  const topicName = topic?.contact?.name || topic?.title || ''
  const isAbbreviatedName = abbreviatedPersonNameRegExp.test(topicName)

  const lawsuitCount = topic?.lawsuitAmount || 0
  const lawsuitsEdges = nullableConnectionNodes(contact?.lawsuits)

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const customLawsuitCount = lawsuitsEdges.length
  const hasLawsuitListMoreThanOnePage = contact?.lawsuits?.pageInfo?.hasNextPage || false
  const displayedLawsuitCount = !hasLawsuitListMoreThanOnePage ? customLawsuitCount : lawsuitCount
  const shouldHideColumn = isEntitiesWidgetExperimentEnabled
    ? isAccordionOpen && displayedLawsuitCount > 10
    : displayedLawsuitCount > 10

  const hasOnlyOneEntity = contact?.lawsuitPartyEntities?.total === 1 && !!lawsuitPartyEntity
  const isSubscriber = viewer?.hasPaidPlan || false
  const hasMultipleProfiles = (viewer?.user?.profiles?.edges?.length || 0) > 1

  const { currentStep } = useUserOnboarding(viewer, topic)
  const eventDispatcher = makeEventDispatcherInstance({
    topic,
    viewer,
    experiments,
    deviceType,
    isMobile,
    referrer,
  })
  const dispatchEvent = eventDispatcher.buildEventDispatcher()

  const dwellTimeListener = useMemo(
    () => {
      return createDwellTimeListener({
        callback: (data) => {
          const eventData = {
            ...data,
            query,
            sanitized_query: getSanitizedQuery({ query }),
            section: artifactPathFromSerpURL(referrer),
            user_id: viewer?.pid,
            metadata: {
              lawsuitCount: topic?.lawsuitAmount,
              personCount: totalCanonicalEntities,
              isSubscriber: viewer?.hasPaidPlan,
              isMonitored: topic?.isMonitoredByViewer,
            },
          }

          return dispatchEvent('Topic.DwellTime', eventData)
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const ga4ViewItemListEventData: GA4ViewItemListRenderProps[] = useMemo(() => {
    return [
      {
        item_variant: 'entity_search_button',
        item_brand: 'name_page_header',
        item_name: null,
        item_id: null,
      },
      ...(hasOnlyOneEntity
        ? [
            {
              item_variant: 'verify_lawsuits',
              item_brand: 'person_card',
              item_name: null,
              item_id: null,
            },
          ]
        : []),
    ]
  }, [hasOnlyOneEntity])

  const { enrichGTMUserData } = useGTMDataLayerWithViewer(viewer)
  const gtm = useGTM()

  useEffect(() => {
    enrichGTMUserData()
  }, [enrichGTMUserData])

  useEffect(() => {
    gtm.push({
      ga4_resource_id: contact?.contactId,
      ga4_content_group: 'name',
      ga4_content_subgroup: hasOnlyOneEntity ? 'unique' : 'namesakes',
    })
  }, [contact?.contactId, gtm, hasOnlyOneEntity])

  useEffect(() => {
    const experiment = experiments?.find((exp) => exp.participating) || experiments?.[0]
    const eventData = {
      from_component: FROM_COMPONENT,
      metadata: {
        query,
        sanitized_query: getSanitizedQuery({ query }),
        section: artifactPathFromSerpURL(referrer),
        lawsuitCount: topic?.lawsuitAmount,
        personCount: totalCanonicalEntities,
        isSubscriber: viewer?.hasPaidPlan,
        isMonitored: topic?.isMonitoredByViewer,
        nameTitle: topicName,
        personCountGeneral: topic?.contact?.lawsuitPartyEntities?.total,
      },
    }

    const hotjarEventData = {
      'Experiment Name': experiment?.experiment || null,
      'Experiment Variation': experiment?.alternative || null,
      'Lawsuit Count': topic?.lawsuitAmount,
      'Person Count': topic?.contact?.lawsuitPartyEntities?.total,
    }

    dispatchEvent('Topic.Viewed', eventData)
    hotjarEvent('identify', viewer?.pid, { ...hotjarEventData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dwellTimeListener.start()

    return () => {
      dwellTimeListener.stop()
    }
  }, [dwellTimeListener])

  const shouldShowEntitiesWidget =
    isEntitiesWidgetExperimentEnabled && lawsuitPartyEntity && !isAbbreviatedName

  if (!topic) {
    return null
  }

  return (
    <>
      <LawsuitTopbar
        currentNavKey={null}
        isLitigantUser={litigantUser}
        isSubscriber={isSubscriber}
        hasMultipleProfiles={hasMultipleProfiles}
        viewer={viewer}
      />
      <ExperimentsProvider experiments={experiments}>
        <PageHead
          topic={topic}
          contact={contact}
          isGoogleBotAgent={isGoogleBotAgent}
          viewer={viewer}
        />
        <ContextProvider
          ssrQueryParams={ssrQueryParams}
          dwellTimeListener={dwellTimeListener}
          eventDispatcher={eventDispatcher}
          isGoogleBotAgent={isGoogleBotAgent}
          isLogged={isLogged}
        >
          <Container className={styles.container} asChild>
            <main>
              <Row>
                <Col lg={7} md={8} xs={12}>
                  <Header
                    topic={topic}
                    contact={contact}
                    displayedLawsuitCount={displayedLawsuitCount}
                    viewer={viewer}
                  />
                  {hasOnlyOneEntity ? (
                    <>
                      <SinglePersonEntityWrapper
                        topic={topic}
                        lawsuitPartyEntity={lawsuitPartyEntity}
                        displayedLawsuitCount={displayedLawsuitCount}
                      />
                      <LawsuitsAccordion
                        topic={topic}
                        displayedLawsuitCount={displayedLawsuitCount}
                        onAccordionStateChange={(isOpen) => setIsAccordionOpen(isOpen)}
                        ga4ViewItemListEventData={ga4ViewItemListEventData}
                      />
                    </>
                  ) : shouldShowEntitiesWidget ? (
                    <>
                      <EntitiesWidget
                        topic={topic}
                        displayedLawsuitCount={displayedLawsuitCount}
                        seed={seed}
                      />
                      <LawsuitsAccordion
                        topic={topic}
                        displayedLawsuitCount={displayedLawsuitCount}
                        onAccordionStateChange={(isOpen) => setIsAccordionOpen(isOpen)}
                        ga4ViewItemListEventData={ga4ViewItemListEventData}
                      />
                    </>
                  ) : (
                    <>
                      <Heading className={styles.listTitle} size="lg">
                        Processos
                      </Heading>
                      <LawsuitList
                        topic={topic}
                        displayedLawsuitCount={displayedLawsuitCount}
                        ga4ViewItemListEventData={ga4ViewItemListEventData}
                      />
                    </>
                  )}
                </Col>
                <Col lg={1} md={0}></Col>
                <Col lg={4} md={4} xs={shouldHideColumn ? 0 : 12}>
                  <RelatedPersonWidget contact={contact} name={topicName} truncateName={true} />
                  <DebugInfo experiments={experiments} topic={topic} viewer={viewer} />
                </Col>
              </Row>
            </main>
          </Container>
        </ContextProvider>
        <BifrostModalTrigger viewer={viewer || null} />
        {currentStep === ONBOARDING_STEPS.SHOW_INTENTIONS && (
          <LawsuitIntentionsModal viewer={viewer} fromComponent={FROM_COMPONENT} />
        )}
        {currentStep === ONBOARDING_STEPS.SHOW_OCCUPATION && (
          <LawsuitOccupationModal viewer={viewer} />
        )}
      </ExperimentsProvider>
    </>
  )
}

const getServerSideProps: GetServerSideProps<NamePageProps & LawsuitAppPageProps> = async (ctx) => {
  const { res, req } = ctx
  const { headers, url } = req

  const litigantUser = isLitigantUser(req.cookies)

  const gqlClientUri = process.env.NEXT_PUBLIC_GRAPHQL_URL
  const gqlServerUri = process.env.LAWSUIT_NAMES_SSR_GQL_URL

  const apolloOptions: ApolloClientOptions = {
    csrUri: gqlClientUri,
    ssrUri: gqlServerUri,
  }
  const { client, withApolloProps } = withApollo(ctx, {
    clientOverrides: apolloOptions,
  })

  const topicId = String(ctx.params?.topicId)
  const urlSlug = String(ctx.params?.slug)
  const ssrQueryParams = getQueryParams(ctx.query)

  const { 'user-agent': userAgent } = headers
  const ua = UAParser(userAgent)

  const experiments = await fetchExperiments(ctx, EXPERIMENTS_CONFIG)
  const isMaskSeniorAgeExperimentEnabled = experiments?.some(
    (exp) => exp.experiment === EXPERIMENTS.MASK_SENIOR_AGE && exp.participating
  )

  const variables: NameRootQueryVariables = {
    tid: parseInt(topicId),
    lawsuitListLimit: LAWSUIT_LIST_LIMIT,
    personsListLimit: PERSONS_LIST_LIMIT,
    after: null,
    maskSeniorAge: isMaskSeniorAgeExperimentEnabled,
  }

  const nameRootResult = await client.query<NameRootQuery>({
    query: PAGE_QUERY,
    variables,
    fetchPolicy: 'network-only',
  })

  const { data } = nameRootResult

  const topic = data?.root?.topic
  const user = data?.root?.viewer?.user
  const isAdmin = user?.isAdmin

  if (!topic) {
    return {
      notFound: true,
    }
  }

  if (topic.isRemoved && !isAdmin) {
    res.statusCode = HTTP_STATUS.PERMANENTLY_REMOVED
    res.end()
    return {
      notFound: true,
    }
  }

  if (topic.slug !== urlSlug) {
    if (user?.isAdmin) {
      return {
        redirect: {
          destination: `/processos/nome/${topic.tid}/${topic.slug}`,
          permanent: false,
        },
      }
    }

    return { notFound: true }
  }

  return {
    props: {
      variables,
      experiments,
      deviceType: ua.device.type || 'desktop',
      isMobile: ua.device.type === 'mobile',
      referrer: headers.referer || '',
      currentUrl: `https://${headers.host}${url}`,
      ssrQueryParams,
      isGoogleBotAgent: verifyGoogleBotAgent(req),
      litigantUser,
      seed: Math.floor(Math.random() * 100000),
      ...(await withTopbarProps(ctx)),
      ...withApolloProps(),
      ...withUserSession(ctx),
    },
  }
}

export const NamePageExports = { getServerSideProps }
