import { ABTestParticipation } from '@jusbrasil-web/shared-ab-test'

export const EXPERIMENTS = {
  ENTITIES_WIDGET: 'entities-widget',
  MASK_SENIOR_AGE: 'mask-senior-age',
  UNIVERSAL_CONTROL: 'universal-control',
}

// Use the same group for all experiments
export const GROUPS = {
  WEB_NAMES: 'web-names',
}

export const VARIANTS = {
  CONTROL: 'control',
  VARIANT_B: 'variantB',
  VARIANT_C: 'variantC',
  VARIANT_D: 'variantD',
}

export const EXPERIMENTS_CONFIG: ABTestParticipation = {
  experiments: Object.values(EXPERIMENTS),
  groups: Object.values(GROUPS),
  participatingOnly: true,
}
