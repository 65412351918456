import {
  Button,
  CourtIcon,
  IconComponent,
  Modal,
  ScalesIcon,
  UserMultiIcon,
  VisuallyHidden,
} from '@farol-ds/react'
import { Image } from '@jusbrasil-web/shared-next-image'

import warningImgSrc from '../../../public/warning.svg'
import styles from './misinformation-modal.module.scss'

interface Section {
  icon: IconComponent
  title: string
  description: () => React.ReactNode
}

const SECTIONS = [
  {
    icon: UserMultiIcon,
    title: 'Atente-se para homônimos',
    description: () =>
      'Esta página pode apresentar processos de pessoas diferentes que têm o mesmo nome',
  },
  {
    icon: CourtIcon,
    title: 'Participar de um processo é um direito',
    description: () => 'Ser parte de um processo não significa ter cometido algum delito',
  },
  {
    icon: ScalesIcon,
    title: 'Entenda a participação no processo',
    description: () => 'É possível que o envolvimento seja como advogado, juiz, entre outros',
  },
]

export function misinformationModal(onOpenChange: (open: boolean) => void) {
  const renderSection = ({ icon: Icon, title, description }: Section) => {
    const key = title.toLowerCase().replace(' ', '-')
    return (
      <li className={styles.section} key={key}>
        <div className={styles.sectionIcon}>
          <Icon size="sm" />
        </div>

        <div>
          <h3 className={styles.sectionTitle}>{title}</h3>
          <p className={styles.sectionValue}>{description()}</p>
        </div>
      </li>
    )
  }

  return (
    <Modal open onOpenChange={onOpenChange} size="sm">
      <Modal.Content>
        <Modal.IconClose />
        <VisuallyHidden>
          <Modal.Header>
            <Modal.HeaderTitle>Desinformação</Modal.HeaderTitle>
          </Modal.Header>
        </VisuallyHidden>
        <Modal.Body className={styles.body}>
          <Image
            alt="Aviso sobre desinformação"
            src={warningImgSrc}
            height={116}
            width={116}
            priority
            className={styles.titleImage}
          />
          <div className={styles.title}>
            <strong>Evite a desinformação</strong>
          </div>
          <div className={styles.description}>Sempre confirme os fatos antes de compartilhar</div>
          <ul className={styles.ul}>{SECTIONS.map(renderSection)}</ul>
          <Button onClick={() => onOpenChange(false)} kind="primary" size="md" block={true}>
            Ok, entendi
          </Button>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}
