import { gql } from '@apollo/client'
import { Button, IconButton, NotificationIcon, NotificationOffIcon } from '@farol-ds/react'
import { LoginTrigger } from '@jusbrasil-web/shared-bifrost'
import { handleTriggerExecution } from '@jusbrasil-web/shared-utils-url'
import { useEffect } from 'react'

import { APP_ID } from '../../utils'
import { useContextData } from '../context-provider'
import { NameMonitoringButton_ViewerFragment } from './__generated__/name-monitoring-button.graphql'
import { useNameMonitoringContext } from './name-monitoring-context'
import { NameMonitoringActionStatus, NameMonitoringStatus } from './types'

export type VariantType = 'desktop' | 'mobile'

interface NameMonitoringButtonProps {
  variant?: VariantType
  className?: string
  viewer: NameMonitoringButton_ViewerFragment | null
}

const LABELS: Record<NameMonitoringStatus, string> = {
  [NameMonitoringStatus.ACTIVE]: 'Deixar de acompanhar',
  [NameMonitoringStatus.INACTIVE]: 'Acompanhar nome',
}

const ICONS: Record<NameMonitoringStatus, React.ReactElement> = {
  [NameMonitoringStatus.ACTIVE]: <NotificationOffIcon data-testid="notification-off-icon" />,
  [NameMonitoringStatus.INACTIVE]: <NotificationIcon data-testid="notification-icon" />,
}

export function NameMonitoringButton(props: NameMonitoringButtonProps) {
  const { variant = 'desktop', className, viewer } = props
  const {
    monitoringAction,
    monitoringActionState,
    monitoringActionPreparing,
    configurePlanActionPreparing,
    monitoringStatus,
    stopMonitoringAction,
  } = useNameMonitoringContext()

  const { ssrQueryParams, dispatchEvent } = useContextData()
  const { isBounce } = ssrQueryParams

  const isLogged = !!viewer?.pid
  const isMonitoring = monitoringStatus === NameMonitoringStatus.ACTIVE
  const isLoading =
    configurePlanActionPreparing ||
    monitoringActionPreparing ||
    monitoringActionState?.status === NameMonitoringActionStatus.LOADING

  const btnAction = isMonitoring ? stopMonitoringAction : monitoringAction

  const handleClickBtnAction = () => {
    const nameEvent = isMonitoring ? 'Lawsuit.CancelMonitorNameClicked' : 'Topic.FollowNameClicked'
    dispatchEvent(nameEvent, {
      from_component: 'NameMonitoringButton',
    })

    btnAction()
  }

  useEffect(() => {
    if (!isMonitoring) {
      handleTriggerExecution({
        triggerName: 'topic-monitoring-flow',
        onCallback: btnAction,
        removeTrigger: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isBounce && !isMonitoring) btnAction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loginOptions = {
    eventData: {
      app_id: APP_ID,
      feature_code: 'alerts',
      from_component: 'NameMonitoringButton',
    },
  }

  if (variant === 'desktop') {
    return (
      <LoginTrigger options={loginOptions} isLogged={isLogged}>
        <Button
          onClick={handleClickBtnAction}
          className={className}
          leftIcon={ICONS[monitoringStatus]}
          loading={isLoading}
        >
          {LABELS[monitoringStatus]}
        </Button>
      </LoginTrigger>
    )
  }

  return (
    <LoginTrigger options={loginOptions} isLogged={isLogged}>
      <IconButton
        onClick={handleClickBtnAction}
        className={className}
        icon={ICONS[monitoringStatus]}
        label={LABELS[monitoringStatus]}
        loading={isLoading}
      />
    </LoginTrigger>
  )
}

NameMonitoringButton.fragments = {
  viewer: gql`
    fragment NameMonitoringButton_viewer on Profile {
      pid
    }
  `,
}
