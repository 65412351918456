import { gql } from '@apollo/client'
import { Nullable } from '@jusbrasil-web/lawsuit/shared'
import Head from 'next/head'

import { getStructuredData } from '../../utils/seo/structured-data'
import {
  PageHead_ContactFragment,
  PageHead_TopicFragment,
  PageHead_ViewerFragment,
} from './__generated__/page-head.graphql'

export type LawsuitNode = NonNullable<
  NonNullable<
    NonNullable<NonNullable<PageHead_ContactFragment['headerLawsuits']>['edges']>[number]
  >['node']
>
export type LawsuitRelatedPeople = NonNullable<LawsuitNode['relatedPeople']>

interface PageHeadProps {
  topic: NonNullable<PageHead_TopicFragment>
  contact: Nullable<PageHead_ContactFragment>
  isGoogleBotAgent: boolean
  viewer: Nullable<PageHead_ViewerFragment>
}

function getProps(
  topic: NonNullable<PageHead_TopicFragment>,
  contact: Nullable<PageHead_ContactFragment>
) {
  const { title: topicTitle, compactTitle, synonyms } = topic

  const getDescription = () => {
    const lawsuitAmount = topic.lawsuitAmount || 0

    const personName = (contact && contact.name) || topicTitle

    const getLawsuitCounterString = (counter: number) =>
      `${counter || 0} processo${counter === 1 ? '' : 's'} que menciona${
        counter === 1 ? '' : 'm'
      } o nome`

    const getTopRelatedPersonsText = () => {
      const persons = contact?.relatedPersonsHead?.edges || []
      return persons.reduce((text, person, idx) => {
        if (idx === 0) {
          return `Desses processos encontrados, ${person?.name} foi a parte que mais apareceu${
            persons.length === 1 ? '.' : ','
          }`
        }

        return `${text} seguido por ${person?.name}.`
      }, '')
    }

    const getTopRelatedCourtText = () => {
      const courts = contact?.relatedLawsuitsByCourt?.edges || []
      return courts.reduce((text, court, idx) => {
        if (idx === 0) {
          if (courts.length === 1 && lawsuitAmount === court?.counter) {
            return `Todos os processos são do ${court?.node?.name}.`
          }

          return `A maioria é do ${court?.node?.name}${courts.length === 1 ? '.' : ','}`
        }

        return `${text} seguido por ${court?.node?.name}.`
      }, '')
    }

    const getTopCourtText = () => {
      if (lawsuitAmount > 0 && contact) {
        return `${getTopRelatedCourtText()} ${getTopRelatedPersonsText()}`
      }

      return ''
    }

    return `O Jusbrasil encontrou ${getLawsuitCounterString(
      lawsuitAmount
    )} ${personName}. ${getTopCourtText()}`
  }

  const title = compactTitle || topicTitle
  const synonymsList = synonyms?.edges?.map((edge) => edge?.node?.title) || []
  const synonymsText = synonymsList.length > 0 ? `${synonymsList.join(', ')}, ` : ''
  return {
    title: `${title} - Processos`,
    description: getDescription(),
    keywords: `${title}, ${synonymsText}processos, consulta processual, justiça, tribunal`,
  }
}

export function PageHead({ topic, contact, viewer, isGoogleBotAgent }: PageHeadProps) {
  const FACEBOOK_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID || ''
  const FACEBOOK_ADMINS = process.env.NEXT_PUBLIC_FACEBOOK_ADMINS || ''

  const { title, description, keywords } = getProps(topic, contact)
  const lawsuits = contact?.headerLawsuits?.edges?.map((e) => e?.node) || []

  const url = contact?.url || ''
  const robotsContent = topic.seoNoIndex ? 'noindex' : 'index,follow'

  const MAX_DESCRIPTION_LENGTH = 300
  const limitedDescription = description.slice(0, MAX_DESCRIPTION_LENGTH)

  const metatags = [
    { name: 'robots', content: robotsContent },
    { name: 'description', content: limitedDescription },
    { name: 'keywords', content: keywords },
    { property: 'fb:app_id', content: FACEBOOK_APP_ID },
    { property: 'fb:admins', content: FACEBOOK_ADMINS },
    { property: 'og:url', content: url },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:width', content: '1200' },
    { property: 'og:height', content: '675' },
    { property: 'og:locale', content: 'pt_BR' },
    { property: 'og:description', content: description },
    { property: 'og:site_name', content: 'Jusbrasil' },
    {
      property: 'og:image',
      content: 'https://static.jusbr.com/images/logo/logo-jusbrasil-1200-675.png',
    },
  ]

  const structuredData = getStructuredData({
    title,
    url,
    description,
    lawsuits: lawsuits as LawsuitNode[],
    isGoogleBotAgent,
    isAdmin: !!viewer?.user?.isAdmin,
  })

  return (
    <Head>
      <title>{title}</title>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      <link rel="canonical" href={url} />
      {metatags.map((props, idx) => (
        <meta {...props} key={idx} />
      ))}
    </Head>
  )
}

PageHead.fragments = {
  viewer: gql`
    fragment PageHead_viewer on Profile {
      user {
        isAdmin
      }
    }
  `,
  topic: gql`
    fragment PageHead_topic on Topic {
      title
      compactTitle
      topicType
      seoNoIndex
      lawsuitAmount
      synonyms(first: 100) {
        edges {
          node {
            title
          }
        }
      }
    }
  `,
  contact: gql`
    fragment PageHead_contact on Contact {
      name
      url
      headerLawsuits: lawsuits(first: $lawsuitListLimit) @connection(key: "PageHead") {
        pageInfo {
          hasNextPage
        }
        edges {
          node {
            id
            url
            title
            number
            judgeName
            kind
            nature
            lastActivityText
            lastActivityDate
            representedPersonLawyers(limit: 10) {
              name
            }
            relatedPeople(limit: 10) {
              name
              poleA
              poleB
            }
            cnjNumber {
              justice {
                name
              }
              court {
                acronym
              }
              source {
                district
              }
              data {
                year
              }
            }
          }
        }
      }
      relatedPersonsHead: relatedPersonsWithMostOccurrences(first: 2) {
        edges {
          counter
          name
          node {
            id
          }
        }
      }
      relatedLawsuitsByCourt: relatedLawsuitsCounterByCourt(first: 2) {
        edges {
          counter
          node {
            name
          }
        }
      }
    }
  `,
}
