import { gql } from '@apollo/client'
import { Accordion, Caption, EyeIcon, Subtitle } from '@farol-ds/react'
import { Nullable } from '@jusbrasil-web/lawsuit/shared'
import { ABTestParticipateResponse } from '@jusbrasil-web/shared-ab-test'
import classNames from 'classnames'

import {
  DebugInfo_TopicFragment,
  DebugInfo_ViewerFragment,
} from './__generated__/debug-info.graphql'
import styles from './debug-info.module.scss'
import { DebugInfoExperiments } from './debug-info-experiments'
import { DebugInfoGeneral } from './debug-info-general'

export interface DebugInfoProps {
  topic: NonNullable<DebugInfo_TopicFragment>
  viewer: Nullable<DebugInfo_ViewerFragment>
  className?: string
  experiments: ABTestParticipateResponse[]
}

export function DebugInfo(props: DebugInfoProps) {
  const { topic, viewer, className, experiments } = props
  const isAdmin = viewer?.user?.isAdmin

  return (
    <>
      {isAdmin && (
        <Accordion collapsible size="md" type="single">
          <Accordion.Item value="1">
            <Accordion.Header>
              <Accordion.Title>
                <div className={classNames(styles.debugInfo, styles.title)}>
                  <Subtitle size="md">Debug</Subtitle>
                  <div className={classNames(styles.debugInfo, styles.admin)}>
                    <EyeIcon size="sm" />
                    <Caption>Admin only</Caption>
                  </div>
                </div>
              </Accordion.Title>
            </Accordion.Header>
            <Accordion.Content asChild>
              <div className={classNames(styles.debugInfo, className)} data-testid="debug-info">
                <DebugInfoGeneral topic={topic} viewer={viewer} />
                <DebugInfoExperiments experiments={experiments} />
              </div>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  )
}

DebugInfo.fragments = {
  topic: gql`
    fragment DebugInfo_topic on Topic {
      ...DebugInfoGeneral_topic
    }

    ${DebugInfoGeneral.fragments.topic}
  `,
  viewer: gql`
    fragment DebugInfo_viewer on Profile {
      ...DebugInfoGeneral_viewer
    }

    ${DebugInfoGeneral.fragments.viewer}
  `,
}

export default DebugInfo
