import { gql } from '@apollo/client'
import { useCallback } from 'react'

import { BifrostModalTriggerOptions } from '../../types'
import { BifrostModalTrigger_ViewerFragment } from './__generated__/bifrost-modal-trigger.graphql'

export interface BifrostModalTriggerProps {
  viewer: BifrostModalTrigger_ViewerFragment | null
  options?: BifrostModalTriggerOptions
}

export function BifrostModalTrigger(props: BifrostModalTriggerProps) {
  const { viewer, options = {} } = props

  const onLoad = useCallback(() => {
    if (window.Bifrost) {
      window.Bifrost.mergeInitialOptions(options)
    } else {
      setTimeout(onLoad, 100)
    }
  }, [options])

  if (viewer) return null

  return (
    <iframe
      title="Jusbrasil"
      src="/cadastro?usage=iframe"
      onLoad={onLoad}
      data-hj-allow-iframe=""
      data-testid="bifrost-iframe"
      frameBorder="0"
      hidden
    />
  )
}

BifrostModalTrigger.fragments = {
  viewer: gql`
    fragment BifrostModalTrigger_viewer on Profile {
      pid
    }
  `,
}
