export enum NameMonitoringStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum NameMonitoringActionStatus {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum NameMonitoringError {
  GENERIC = 'GENERIC',
  TOO_BROAD = 'TOO_BROAD',
  LIMIT_REACHED = 'LIMIT_REACHED',
}

export type NameMonitoringActionState = {
  status: NameMonitoringActionStatus
  errorType?: NameMonitoringError | null
}
