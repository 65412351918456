import { useEffect, useState } from 'react'

export type ViewPortSize = {
  width: number
  height: number
}

export type UseViewportSizeProps = {
  initialDelay?: number
  delay?: number
  initialWidth?: number
  initialHeight?: number
}

export function useViewportSize({
  delay = 300,
  initialDelay = 0,
  initialWidth = 0,
  initialHeight = 0,
}: UseViewportSizeProps = {}) {
  const [viewPortSize, setViewPortSize] = useState<ViewPortSize>({
    width: initialWidth,
    height: initialHeight,
  })
  let timeoutId: NodeJS.Timeout

  const resizeListener = (delay: number) => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(
      () =>
        setViewPortSize({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      delay
    )
  }

  useEffect(() => {
    resizeListener(initialDelay)

    const resizeHandler = () => resizeListener(delay)
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return viewPortSize
}
