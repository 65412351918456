import { DwellTimeListener } from '@jusbrasil-web/shared-dwell-time'
import { createContext, useContext, useState } from 'react'

import { ServerQueryParams } from '../../types'
import { EventDispatcherInterface } from '../../utils'
import { SearchByCpfModal } from '../search-by-cpf-modal'

interface ContextProviderProps {
  children: React.ReactNode
  ssrQueryParams: ServerQueryParams
  dwellTimeListener: DwellTimeListener
  eventDispatcher: EventDispatcherInterface
  isGoogleBotAgent?: boolean
  isLogged?: boolean
}

interface ContextDataInterface {
  ssrQueryParams: ServerQueryParams
  showModalSearchByCpf: boolean
  setShowModalSearchByCpf: (value: boolean) => void
  dispatchEvent: (event: string, data?: Record<string, unknown>) => void
  dwellTimeListener: DwellTimeListener
  isGoogleBotAgent?: boolean
  isLogged?: boolean
}

const Context = createContext<ContextDataInterface | null>(null)

export const useContextData = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useContextData must be used within a ContextProvider')
  }
  return context
}

export function ContextProvider(props: ContextProviderProps) {
  const {
    children,
    ssrQueryParams,
    eventDispatcher,
    dwellTimeListener,
    isGoogleBotAgent,
    isLogged,
  } = props

  const [showModalSearchByCpf, setShowModalSearchByCpf] = useState(false)
  const dispatchEvent = eventDispatcher.buildEventDispatcher()

  const contextValue = {
    ssrQueryParams,
    showModalSearchByCpf,
    setShowModalSearchByCpf,
    dwellTimeListener,
    dispatchEvent,
    isGoogleBotAgent,
    isLogged,
  }

  return (
    <Context.Provider value={contextValue}>
      {children}
      {showModalSearchByCpf && <SearchByCpfModal />}
    </Context.Provider>
  )
}
