import { gql } from '@apollo/client'
import { Nullable } from '@jusbrasil-web/lawsuit/shared'
import { ABTestParticipateResponse } from '@jusbrasil-web/shared-ab-test'
import { sendMetrics } from '@jusbrasil-web/shared-utils-events'

import { APP_ID } from '../../constants'
import {
  MakeEventDispatcherInstance_TopicFragment,
  MakeEventDispatcherInstance_ViewerFragment,
} from './__generated__/event-dispatcher.graphql'

interface EventDispatcherProps {
  topic: Nullable<MakeEventDispatcherInstance_TopicFragment>
  viewer: Nullable<MakeEventDispatcherInstance_ViewerFragment>
  experiments: ABTestParticipateResponse[]
  deviceType: string
  isMobile: boolean
  referrer: string
}

interface EventData {
  app_id?: string
  artifact?: string
  detected_entity_type?: string
  from_component?: string
  dvce_ismobile?: boolean
  dvce_type?: string
  referrer?: string
  source?: string
  topic_id?: number
  topic_type?: string
  origin?: string
  contact_id?: string
  dwell_time_ms?: number
  metadata?: Record<string, Nullable<string | number | boolean>>
}

export interface EventDispatcherInterface {
  buildEventDispatcher(): (event: string, data?: EventData) => void
}

export class EventDispatcher {
  private props: EventDispatcherProps
  private defaultEventData: EventData = {}

  constructor(props: EventDispatcherProps) {
    this.props = props
    this.buildEventData()
  }

  private buildEventData() {
    const { topic, experiments, isMobile, deviceType, referrer } = this.props
    const experiment = experiments.find((exp) => exp.participating) || null

    if (!topic) return {}

    this.defaultEventData = {
      app_id: APP_ID,
      contact_id: topic?.contact?.contactId || '',
      topic_id: topic.tid,
      topic_type: topic.topicType || '',
      origin: topic.origin || '',
      detected_entity_type: topic.detectedEntityType || '',
      dvce_ismobile: isMobile,
      dvce_type: deviceType,
      referrer: referrer,
      source: referrer,
      metadata: {
        alternative: experiment?.alternative || null,
        experiment: experiment?.experiment || null,
        participatingAbTest: Boolean(experiment?.participating),
      },
    }
  }

  private normalizeEvent(event: string, data: EventData = {}) {
    const { topic } = this.props
    const defaultEventData = this.defaultEventData
    const metadata = data?.metadata || {}

    const title = topic?.contact?.name || topic?.title || null

    switch (event) {
      case 'Lawsuit.CheckLawsuitsOfaNameClicked':
      case 'Lawsuit.CancelMonitorNameClicked':
      case 'Lawsuit.CancelMonitorNameConfirmed':
        return {
          ...defaultEventData,
          ...data,
          metadata: {
            ...defaultEventData.metadata,
            ...metadata,
            term: title,
          },
        }
      default:
        return {
          ...defaultEventData,
          ...data,
          metadata: {
            ...defaultEventData.metadata,
            ...metadata,
          },
        }
        break
    }
  }

  buildEventDispatcher() {
    const { viewer } = this.props
    this.buildEventData()

    return (event: string, data: EventData = {}) => {
      const eventData = this.normalizeEvent(event, data)

      sendMetrics(event, viewer?.pid?.toString(), eventData)
    }
  }
}

export function makeEventDispatcherInstance(props: EventDispatcherProps) {
  const { topic, viewer, experiments, isMobile, deviceType, referrer } = props

  return new EventDispatcher({
    topic,
    viewer,
    experiments,
    isMobile,
    deviceType,
    referrer,
  })
}

makeEventDispatcherInstance.fragments = {
  topic: gql`
    fragment MakeEventDispatcherInstance_topic on Topic {
      tid
      title
      lawsuitAmount
      detectedEntityType
      topicType
      origin
      isMonitoredByViewer
      contact {
        totalCanonicalEntities
        contactId
        name
      }
    }
  `,
  viewer: gql`
    fragment MakeEventDispatcherInstance_viewer on Profile {
      pid
      hasPaidPlan
    }
  `,
}
