import { gql } from '@apollo/client'
import { LockModalData } from '@jusbrasil-rc/utils-box'
import { useConfigurePlanModal } from '@jusbrasil-web/lawsuit/shared'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import {
  COMPONENT_CLASS,
  FEATURE_CLASS,
  FEATURE_NAME,
  GA4FeatureUseEvent,
  REQUIRED_USER_STATUS,
} from '@jusbrasil-web/shared-ga4-events'
import { handleTriggerExecution } from '@jusbrasil-web/shared-utils-url'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'

import { useNameMonitoringModal } from '../../hooks/use-name-monitoring-modal'
import { APP_ID, STOP_MONITORING_NAME_TRIGGER } from '../../utils'
import { useContextData } from '../context-provider'
import {
  NameMonitoringContextProvider_TopicFragment,
  NameMonitoringContextProvider_ViewerFragment,
} from './__generated__/name-monitoring-context.graphql'
import { useCreatePublicationMonitoringMutation } from './mutations/create-publication-monitoring'
import { NameMonitoringResultAlert } from './name-monitoring-result-alert'
import { StopNameMonitoringConfirm } from './stop-name-monitoring-confirm'
import {
  NameMonitoringActionState,
  NameMonitoringActionStatus,
  NameMonitoringError,
  NameMonitoringStatus,
} from './types'

interface NameMonitoringContextType {
  monitoringStatus: NameMonitoringStatus
  monitoringActionState: NameMonitoringActionState | null
  monitoringActionPreparing: boolean // pre-load state before actually executing the action (eg. opening the modal or prefetching data)
  configurePlanActionPreparing: boolean
  monitoringAction: () => void
  stopMonitoringAction: () => void
}

interface NameMonitoringContextProviderProps {
  children: ReactNode
  viewer: NameMonitoringContextProvider_ViewerFragment | null
  topic: NonNullable<NameMonitoringContextProvider_TopicFragment>
}

const NameMonitoringContext = createContext<NameMonitoringContextType | undefined>(undefined)

export function useNameMonitoringContext() {
  const context = useContext(NameMonitoringContext)

  if (!context) {
    throw new Error('useNameMonitoringContext must be used within a NameMonitoringContextProvider')
  }

  return context
}

export function NameMonitoringContextProvider(props: NameMonitoringContextProviderProps) {
  const { children, topic, viewer } = props
  const { isMonitoredByViewer, contact } = topic
  const { monitorPublications } = viewer || {}
  const contactId = contact?.contactId

  const isLogged = !!viewer?.pid
  const monitorPublicationsLimit = monitorPublications?.limit || 0
  const canMonitorPublication = topic?.canMonitorPublication || false

  const hasMonitorFeature = monitorPublicationsLimit > 0
  const isMonitoring = !!isMonitoredByViewer && hasMonitorFeature
  const featureLimitReached = !canMonitorPublication && !isMonitoring

  const { ssrQueryParams } = useContextData()
  const { sendGA4Event } = useGA4Dispatcher()

  const { utmSource } = ssrQueryParams

  const [monitoringStatus, setMonitoringStatus] = useState<NameMonitoringStatus>(
    isMonitoring ? NameMonitoringStatus.ACTIVE : NameMonitoringStatus.INACTIVE
  )
  const [monitoringActionState, setMonitoringActionState] =
    useState<NameMonitoringActionState | null>(null)

  const [showStopMonitoringConfirm, setShowStopMonitoringConfirm] = useState(false)

  const createMonitoringMutation = useCreatePublicationMonitoringMutation(
    topic.tid,
    topic.title || ''
  )

  const { open: openNameMonitoringModal, loading: monitoringActionPreparing } =
    useNameMonitoringModal()
  const { open: openConfigurePlanModal, loading: configurePlanActionPreparing } =
    useConfigurePlanModal()

  const monitoringAction = () => {
    if (!isLogged) {
      dispatchGA4Event(false)
      return
    }

    if (monitoringStatus === NameMonitoringStatus.ACTIVE) return

    if (!hasMonitorFeature) {
      dispatchGA4Event(false)
      return openNameMonitoringModal({
        topic: topic,
        appId: APP_ID,
        utmSource,
        eventData: {}, // TODO: Implement the eventData - @rogsilva
        eventNamespace: 'Topic',
        fromComponent: 'NameMonitoringContextProvider',
      })
    }

    if (featureLimitReached) {
      dispatchGA4Event?.(false)
      return openConfigurePlanModal({
        modalData: LockModalData.monitorPublications({
          fromComponent: 'NameMonitoringContextProvider',
        }),
      })
    }

    setMonitoringActionState({ status: NameMonitoringActionStatus.LOADING })
    createMonitoringMutation()
      .then(() => {
        setMonitoringStatus(NameMonitoringStatus.ACTIVE)
        setMonitoringActionState({ status: NameMonitoringActionStatus.SUCCESS })
        dispatchGA4Event(true)
      })
      .catch((error) => {
        const isTooBroadError = error?.message?.includes('too broad')
        setMonitoringActionState({
          status: NameMonitoringActionStatus.ERROR,
          errorType: isTooBroadError ? NameMonitoringError.TOO_BROAD : NameMonitoringError.GENERIC,
        })
        dispatchGA4Event(false)
      })
  }

  const stopMonitoringAction = useCallback(() => {
    if (monitoringStatus === NameMonitoringStatus.INACTIVE) return
    setShowStopMonitoringConfirm(true)
  }, [monitoringStatus])

  const stopMonitoringSuccessCallback = () => {
    setMonitoringStatus(NameMonitoringStatus.INACTIVE)
    dispatchGA4Event(true)
  }

  const handleAlertClose = () => {
    setMonitoringActionState(null)
  }

  const dispatchGA4Event = (successfulAttempt: boolean) => {
    const isMonitoringEvent = monitoringStatus === NameMonitoringStatus.ACTIVE
    sendGA4Event(
      new GA4FeatureUseEvent({
        componentClass: COMPONENT_CLASS.NAME_PAGE_HEADER,
        componentName: null,
        featureClass: FEATURE_CLASS.NAME_MONITORING,
        featureName: isMonitoringEvent
          ? FEATURE_NAME.DISABLE_NAME_MONITORING
          : FEATURE_NAME.ENABLE_NAME_MONITORING,
        resourceId: contactId,
        requiredUserStatus: REQUIRED_USER_STATUS.SUBSCRIBER_USER,
        selectedOption: `${isMonitoringEvent ? 'disable_' : ''}monitoring_button`,
        successfulAttempt,
      })
    )
  }

  useEffect(() => {
    handleTriggerExecution({
      triggerName: STOP_MONITORING_NAME_TRIGGER,
      onCallback: stopMonitoringAction,
    })
  }, [stopMonitoringAction])

  return (
    <NameMonitoringContext.Provider
      value={{
        monitoringStatus,
        monitoringActionState,
        monitoringActionPreparing,
        configurePlanActionPreparing,
        monitoringAction,
        stopMonitoringAction,
      }}
    >
      {children}
      <NameMonitoringResultAlert onClose={handleAlertClose} />
      <StopNameMonitoringConfirm
        topic={topic}
        isOpen={showStopMonitoringConfirm}
        onClose={() => setShowStopMonitoringConfirm(false)}
        onSuccessCallback={stopMonitoringSuccessCallback}
        onErrorCallback={() => dispatchGA4Event(false)}
      />
    </NameMonitoringContext.Provider>
  )
}

NameMonitoringContextProvider.fragments = {
  topic: gql`
    fragment NameMonitoringContextProvider_topic on Topic {
      tid
      title
      isMonitoredByViewer
      canMonitorPublication: viewerCanUseFeature(featureCode: "monitor_publications")
      contact {
        contactId
      }
      ...StopNameMonitoringConfirm_topic
    }

    ${StopNameMonitoringConfirm.fragments.topic}
  `,
  viewer: gql`
    fragment NameMonitoringContextProvider_viewer on Profile {
      pid
      monitorPublications: featureTokens(featureCode: "monitor_publications") {
        limit
      }
    }
  `,
}
