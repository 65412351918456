import { gql } from '@apollo/client'
import {
  ArrowRightIcon,
  BodyText,
  Button,
  Card,
  Container,
  Detail,
  Heading,
  UserMultiIcon,
} from '@farol-ds/react'
import { useExperiments } from '@jusbrasil-web/lawsuit/shared'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'
import { LoginTrigger } from '@jusbrasil-web/shared-bifrost'
import { getJusbrasilBaseURL } from '@jusbrasil-web/shared-utils-url'

import { APP_ID } from '../../utils'
import { EXPERIMENTS, VARIANTS } from '../../utils/experiments'
import { shuffleArray } from '../../utils/shuffle'
import { useContextData } from '../context-provider'
import { PersonEntityCard } from '../person-entity-card'
import { EntitiesWidget_TopicFragment } from './__generated__/entities-widget.graphql'
import styles from './entities-widget.module.scss'

interface EntitiesWidgetProps {
  topic: NonNullable<EntitiesWidget_TopicFragment>
  displayedLawsuitCount: number
  seed: number
}

export function EntitiesWidget({ topic, displayedLawsuitCount, seed }: EntitiesWidgetProps) {
  const { dispatchEvent, isLogged } = useContextData()

  const name = topic?.contact?.name || topic.title
  const totalCanonicalEntities = topic?.contact?.totalCanonicalEntities || 0
  const lawsuitPartyEntities = topic?.contact?.lawsuitPartyEntities
  const totalEntities = lawsuitPartyEntities?.total || 0
  const shuffledEntities = shuffleArray(nullableConnectionNodes(lawsuitPartyEntities), seed)

  const { getExperiment } = useExperiments()
  const experimentEntitiesWidget = getExperiment(EXPERIMENTS.ENTITIES_WIDGET)

  const isVariantC =
    experimentEntitiesWidget?.participating &&
    experimentEntitiesWidget?.alternative === VARIANTS.VARIANT_C

  const isVariantB =
    experimentEntitiesWidget?.participating &&
    experimentEntitiesWidget?.alternative === VARIANTS.VARIANT_B

  const fromComponent = isVariantC ? 'NameToSERPWidget' : isVariantB ? 'NameToSERPButton' : null
  const baseUrl = getJusbrasilBaseURL()
  const query = name?.replace(/\s+/g, '+') || null
  const redirectionUrl = `${baseUrl}/pessoa/busca?q=${query}&fromComponent=${fromComponent}&lawsuitCount=${displayedLawsuitCount}&personCount=${totalCanonicalEntities}&personCountGeneral=${totalEntities}`

  function onClick(): void {
    dispatchEvent('Topic.ArtifactFilterClicked', {
      from_component: fromComponent,
      artifact: 'PESSOA',
      metadata: {
        personCount: totalCanonicalEntities,
        personCountGeneral: totalEntities,
        lawsuitCount: displayedLawsuitCount,
      },
    })
    if (isLogged) window.open(`${redirectionUrl}`, '_self')
  }

  const loginOptions = {
    nextUrl: `${redirectionUrl}`,
    eventData: {
      app_id: APP_ID,
      feature_code: 'unlock_entities_widget',
      from_component: 'EntitiesWidget',
    },
  }

  return (
    <>
      {isVariantC && (
        <LoginTrigger options={loginOptions} isLogged={isLogged}>
          <Card className={styles.card} onClick={onClick} data-testid="entity-widget-card">
            <Heading>
              <Detail>
                <Detail.Icon>
                  <UserMultiIcon />
                </Detail.Icon>
                <Detail.Label className={styles.label} data-testid="entity-widget-card-title">
                  {totalEntities} pessoas identificadas
                </Detail.Label>
              </Detail>
            </Heading>
            <BodyText className={styles.subtitle} data-testid="entity-widget-card-description">
              Selecione entre todas as pessoas encontradas com o nome <strong>{name}</strong>.
            </BodyText>
            <Button
              className={styles.button}
              kind="secondary"
              size="md"
              rightIcon={<ArrowRightIcon />}
              data-testid="entity-widget-card-button"
            >
              Exibir todas as pessoas
            </Button>
          </Card>
        </LoginTrigger>
      )}

      {isVariantB && (
        <Container className={styles.list} data-testid="entity-widget-list">
          <Heading size="md" data-testid="entity-widget-list-title" asChild>
            <h2>{totalEntities} pessoas identificadas</h2>
          </Heading>
          <BodyText size="md" data-testid="entity-widget-list-description">
            Selecione a pessoa e verifique se existem processos pelo CPF dela.
          </BodyText>
          {shuffledEntities &&
            shuffledEntities
              .slice(0, 3)
              .map((entity, index) => (
                <PersonEntityCard
                  key={`card-${entity.entityId}`}
                  partyEntity={entity}
                  fromComponent="EntitiesWidget"
                  personPosition={index + 1}
                  totalEntitiesRelated={totalEntities}
                  totalCanonicalEntities={totalCanonicalEntities}
                />
              ))}
          <LoginTrigger options={loginOptions} isLogged={isLogged}>
            <Button
              className={styles.list__button}
              data-testid="entity-widget-list-button"
              kind="secondary"
              size="md"
              rightIcon={<ArrowRightIcon />}
              onClick={onClick}
            >
              Exibir todas as pessoas
            </Button>
          </LoginTrigger>
        </Container>
      )}
    </>
  )
}

EntitiesWidget.fragments = {
  topic: gql`
    fragment EntitiesWidget_topic on Topic {
      title
      contact {
        name
        totalCanonicalEntities
        lawsuitPartyEntities(first: 10, source: "haystack") {
          total
          edges {
            node {
              ...PersonEntityCard_lawsuitPartyEntity
            }
          }
        }
      }
    }
    ${PersonEntityCard.fragments.lawsuitPartyEntity}
  `,
}
