import { pluralize } from '@jusbrasil-web/shared-utils-format'

const numberFormat = new Intl.NumberFormat('pt-BR')
const getFormattedNumber = (count: number) => numberFormat.format(count)

export const buildLawsuitCount = (count: number) => {
  const formattedCount = getFormattedNumber(count)
  const lawsuitText = pluralize(count, 'processo', 'processos')

  return (
    <strong>
      {count > 1 && <>{formattedCount} </>}
      {lawsuitText}
    </strong>
  )
}
