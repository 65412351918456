import { gql } from '@apollo/client'
import { InfiniteList, LawsuitCard, usePagination } from '@jusbrasil-web/lawsuit/shared'
import { nullableConnectionNodes } from '@jusbrasil-web/shared-apollo-client/utilities'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import {
  COMPONENT_CLASS,
  COMPONENT_NAME,
  GA4ClickEvent,
  GA4ViewItemListEvent,
  GA4ViewItemListRenderProps,
  SELECTED_OPTION,
} from '@jusbrasil-web/shared-ga4-events'
import { useEffect, useMemo, useState } from 'react'

import { LAWSUIT_LIST_LIMIT } from '../../utils'
import { useContextData } from '../context-provider'
import { LawsuitListEmpty } from '../lawsuit-list-empty'
import {
  LawsuitList_LawsuitsFragment,
  LawsuitList_TopicFragment,
  LawsuitsListQuery,
  LawsuitsListQueryVariables,
} from './__generated__/lawsuit-list.graphql'

const FRAGMENT = gql`
  fragment LawsuitList_lawsuits on ContactLawsuitsConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        lawsuitId
        topicSourceId
        title
        number
        ...LawsuitCard_lawsuit
      }
    }
  }

  ${LawsuitCard.fragments.lawsuit}
`

LawsuitList.fragments = {
  topic: gql`
    fragment LawsuitList_topic on Topic {
      tid
      lawsuitAmount
      title
      contact {
        id
        contactId
        name
        lawsuits(first: $lawsuitListLimit, after: $after) @connection(key: "LawsuitList") {
          ...LawsuitList_lawsuits
        }
      }
    }

    ${FRAGMENT}
  `,
}

export const LAWSUITS_QUERY = gql`
  query lawsuitsList($contactId: String!, $lawsuitListLimit: Int!, $after: String) {
    root {
      contact(contactId: $contactId) {
        id
        lawsuits(first: $lawsuitListLimit, after: $after) @connection(key: "LawsuitList") {
          ...LawsuitList_lawsuits
        }
      }
    }
  }

  ${FRAGMENT}
`

type LawsuitNode = NonNullable<
  NonNullable<NonNullable<LawsuitList_LawsuitsFragment['edges']>[number]>['node']
>

interface LawsuitListProps {
  topic: NonNullable<LawsuitList_TopicFragment>
  displayedLawsuitCount: number
  ga4ViewItemListEventData?: GA4ViewItemListRenderProps[]
}

export function LawsuitList(props: LawsuitListProps) {
  const { dispatchEvent, dwellTimeListener } = useContextData()
  const { topic, displayedLawsuitCount, ga4ViewItemListEventData } = props

  const { sendGA4Event } = useGA4Dispatcher()
  const [updatedLawsuitsEdgesList, setUpdatedLawsuitsEdgesList] = useState<LawsuitNode[]>([])

  const cursor = topic?.contact?.lawsuits?.pageInfo?.endCursor

  const variables: LawsuitsListQueryVariables = useMemo(
    () => ({
      contactId: topic?.contact?.contactId as NonNullable<string>,
      lawsuitListLimit: LAWSUIT_LIST_LIMIT,
      after: cursor ?? null,
    }),
    [topic?.contact?.contactId, cursor]
  )

  const { loading, hasNextPage, handleLoadMore, hasError } = usePagination<
    LawsuitsListQuery,
    LawsuitsListQueryVariables
  >(LAWSUITS_QUERY, variables, topic?.contact?.lawsuits?.pageInfo, 'root.contact.lawsuits.pageInfo')

  const lawsuitsEdges = nullableConnectionNodes(topic?.contact?.lawsuits)

  const topicName = topic?.contact?.name || topic.title || ''

  const fetchMoreLawsuitsEdges = () => {
    if (!loading) {
      handleLoadMore()
    }
  }

  useEffect(() => {
    const lawsuitsEdgesList = getLastLoadedLawsuits()

    const lawsuitEdgesListRender = lawsuitsEdgesList?.map((lawsuit) => ({
      item_variant: 'show_lawsuit',
      item_brand: 'lawsuit_card',
      item_name: 'name_related_lawsuit',
      item_id: lawsuit.lawsuitId,
    })) as GA4ViewItemListRenderProps[]

    const isFirstRender = updatedLawsuitsEdgesList.length === 0
    const renders = [
      ...(isFirstRender ? ga4ViewItemListEventData ?? [] : []),
      ...lawsuitEdgesListRender,
    ]

    sendGA4Event(
      new GA4ViewItemListEvent({
        componentClass: null,
        componentName: null,
        renders,
      })
    )

    setUpdatedLawsuitsEdgesList(lawsuitsEdges)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawsuitsEdges.length])

  const getLastLoadedLawsuits = () => {
    return lawsuitsEdges.length > updatedLawsuitsEdgesList.length
      ? lawsuitsEdges.filter(
          (lawsuit) =>
            !updatedLawsuitsEdgesList.some(
              (updatedLawsuit) => lawsuit.lawsuitId === updatedLawsuit.lawsuitId
            )
        )
      : lawsuitsEdges
  }

  const handleClickedLawsuit = (
    topic: NonNullable<LawsuitList_TopicFragment>,
    lawsuit: LawsuitNode,
    idx: number
  ) => {
    const eventData = {
      from_component: 'LawsuitList',
      metadata: {
        dwellTimeMs: Date.now() - dwellTimeListener.startDate,
        lawsuitPosition: idx + 1,
        lawsuitTopicId: lawsuit.topicSourceId,
        lawsuitTitle: lawsuit.title,
        nameTitle: topic?.contact?.name || topic.title || '',
        lawsuit_count: displayedLawsuitCount,
        name_topic_id: topic.tid,
      },
    }

    dispatchEvent('Lawsuit.NamePageLawsuitClick', eventData)
    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.LAWSUIT_CARD,
        componentName: COMPONENT_NAME.NAME_RELATED_LAWSUIT,
        selectedOption: SELECTED_OPTION.SHOW_LAWSUIT,
        resourceId: lawsuit.lawsuitId,
      })
    )
  }

  function renderLawsuit(lawsuit: LawsuitNode, idx: number) {
    return (
      <LawsuitCard
        lawsuit={lawsuit}
        showLawsuitNumber
        showOldButton
        showKind
        showParts={true}
        label={`Processo nº ${lawsuit.number}`}
        onClick={() => handleClickedLawsuit(topic, lawsuit, idx)}
      />
    )
  }

  return (
    <InfiniteList<LawsuitNode>
      items={lawsuitsEdges}
      hasNextPage={Boolean(hasNextPage())}
      loading={loading}
      renderItem={renderLawsuit}
      onLoadMore={fetchMoreLawsuitsEdges}
      renderEmpty={() => <LawsuitListEmpty name={topicName} />}
      retry={{ show: !!hasError }}
      itemsThreshold={4}
      enableSpinner
    />
  )
}
