import { Button, FormControl, Input, Modal } from '@farol-ds/react'
import { validateCpf } from '@jusbrasil-web/shared-utils-validators'
import { FormEvent, useState } from 'react'
import { useIMask } from 'react-imask'

import { CPF_REGEX, ERROR_MESSAGES } from '../../utils'

interface SearchByCpfFormProps {
  handleClickSearch: (unmaskedCpf: string, maskedCpf: string) => void
}

type ErrorMessageKey = keyof typeof ERROR_MESSAGES

export function SearchByCpfForm(props: SearchByCpfFormProps) {
  const { handleClickSearch } = props
  const [errorForm, setErrorForm] = useState<ErrorMessageKey | null>(null)

  const {
    ref: cpfRef,
    unmaskedValue: unmaskedCpf,
    value: maskedCpf,
  } = useIMask({
    mask: '000.000.000-00',
    lazy: false,
  })

  const validateForm = (cpf: string) => {
    if (!cpf) {
      return 'EMPTY_CPF'
    }

    const isCPFNumber = cpf?.match(CPF_REGEX)
    const isCPFValid = cpf && validateCpf(cpf)

    if (!isCPFNumber || !isCPFValid) {
      return 'INVALID_CPF_NUMBER'
    }

    return
  }

  const onSubmit = (evt: FormEvent) => {
    evt.preventDefault()
    const error = validateForm(unmaskedCpf)

    if (error) {
      setErrorForm(error)
      return
    }
    handleClickSearch(unmaskedCpf, maskedCpf)
  }

  return (
    <>
      <Modal.IconClose data-testid="lawsuit-names-search-by-cpf-close" />
      <Modal.Header>
        <Modal.HeaderTitle>Buscar por CPF</Modal.HeaderTitle>
        <Modal.HeaderDescription>
          Essa informação será utilizada apenas para encontrar a pessoa que você está buscando.
        </Modal.HeaderDescription>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={onSubmit} noValidate>
          <FormControl required error={!!errorForm}>
            <FormControl.Label>CPF</FormControl.Label>
            <Input name="cpf" inputMode="numeric" ref={cpfRef} />
            <FormControl.ErrorMessage>
              {errorForm && ERROR_MESSAGES[errorForm]}
            </FormControl.ErrorMessage>
          </FormControl>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onSubmit}
          kind="primary"
          data-testid="lawsuit-names-search-by-cpf-submit-button"
        >
          Buscar
        </Button>
      </Modal.Footer>
    </>
  )
}
