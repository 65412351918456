import { withPrometheusMetricsGetServerSideProps } from '@jusbrasil-web/core-next'
import { NamePage, NamePageExports } from '@jusbrasil-web/lawsuit/names'

const { getServerSideProps: getServerSidePropsRaw } = NamePageExports
const getServerSideProps = withPrometheusMetricsGetServerSideProps(
  __filename,
  getServerSidePropsRaw,
  true
)
export { getServerSideProps }
export default NamePage
