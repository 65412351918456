type DwellTimeMetrics = {
  dwell_time_ms: number
  reading_time_ms?: number
  read_percentage?: number
}
type CallbackFunction = (metrics: DwellTimeMetrics) => void

interface DwellTimeListenerProps {
  callback: CallbackFunction
  readingTimeSeconds?: number
}

const MAX_TIME_MS = 2e9

export class DwellTimeListener {
  elapsedTimeMs: number
  startDate: number
  isStarted: boolean
  callback: CallbackFunction
  readingTimeMs?: number

  constructor(props: DwellTimeListenerProps) {
    this.elapsedTimeMs = 0
    this.startDate = 0
    this.isStarted = false
    this.callback = props.callback
    this.readingTimeMs = props.readingTimeSeconds ? props.readingTimeSeconds * 1000 : undefined
  }

  start() {
    if (this.isStarted) return
    this.elapsedTimeMs = 0
    this.startDate = Date.now()

    document.addEventListener('visibilitychange', this.onVisibilityChange)
    window.addEventListener('beforeunload', this.onBeforeUnload)
    this.isStarted = true
  }

  stop() {
    if (!this.isStarted) return
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
    window.removeEventListener('beforeunload', this.onBeforeUnload)
    this.isStarted = false

    const metrics = this.calculateMetrics()
    this.callback(metrics)
  }

  private onVisibilityChange = () => {
    if (document.hidden) {
      this.elapsedTimeMs += Date.now() - this.startDate
    } else {
      this.startDate = Date.now()
    }
  }

  private onBeforeUnload = () => {
    this.stop()
  }

  private calculateReadPercentage() {
    return this.readingTimeMs
      ? parseFloat(((this.elapsedTimeMs / this.readingTimeMs) * 100).toPrecision(3))
      : 0
  }

  private calculateMetrics() {
    if (!document.hidden) {
      this.elapsedTimeMs += Date.now() - this.startDate
    }

    if (this.elapsedTimeMs > MAX_TIME_MS) {
      this.elapsedTimeMs = MAX_TIME_MS
    }

    const metrics: DwellTimeMetrics = { dwell_time_ms: this.elapsedTimeMs }

    if (this.readingTimeMs) {
      metrics.reading_time_ms = this.readingTimeMs
      metrics.read_percentage = this.calculateReadPercentage()
    }

    return metrics
  }
}

export function createDwellTimeListener(props: DwellTimeListenerProps) {
  return new DwellTimeListener(props)
}
