import { RefObject, useEffect, useRef } from 'react'

type Callback<K> = (event: K) => void

export function useClickOutside<K extends Event = MouseEvent | TouchEvent>(
  ref: RefObject<HTMLElement>,
  callback: Callback<K>
): void {
  const callbackRef = useRef<Callback<K>>()

  useEffect(() => {
    callbackRef.current = callback

    const listener: EventListener = (evt) => {
      if (!ref.current || !evt.target || !callbackRef.current) return
      if (!ref.current.contains(evt.target as Node)) {
        callbackRef.current(evt as K)
      }
    }

    document.addEventListener('click', listener, true)
    document.addEventListener('touchstart', listener, true)

    return () => {
      document.removeEventListener('click', listener, true)
      document.removeEventListener('touchstart', listener, true)
    }
  })
}
