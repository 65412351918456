export const LAWSUIT_PARTY_ENTITIES_LIMIT = 20
export const LAWSUIT_LIST_LIMIT = 10
export const PERSONS_LIST_LIMIT = 3
export const JUSBRASIL_ROOT_URL = 'https://www.jusbrasil.com.br'
export const APP_ID = 'lawsuit-names'
export const STOP_MONITORING_NAME_TRIGGER = 'stop-monitoring-name'

export const ERROR_MESSAGES = {
  EMPTY_CPF: 'O CPF é obrigatório',
  INVALID_CPF_NUMBER: 'CPF inválido',
}

export const CPF_REGEX = /(\d{3}\p{P}?\d{3}\p{P}?\d{3}\p{P}?\d{2})/u

export const HTTP_STATUS = {
  PERMANENTLY_REMOVED: 410,
}
