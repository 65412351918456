import { formatToRelativeDate } from '@jusbrasil-web/shared-relative-date'
import {
  getBreadcrumb,
  getStructuredDataSchema,
  getWebpage,
  SEOItemListElement,
} from '@jusbrasil-web/shared-utils-seo'

import { LawsuitNode } from '../../components'
import { toBrazilianDate } from '../format-date'

const getItemList = (
  title: string,
  url: string,
  description: string,
  itemListElement: SEOItemListElement[]
) => ({
  '@context': 'https://schema.org',
  '@type': 'ItemList',
  name: title,
  description,
  url,
  itemListElement,
})

const getItemListElement = (position: number, url: string, title: string, description: string) => ({
  '@type': 'ListItem',
  position,
  item: {
    '@id': url,
    name: title,
    description,
  },
})

export const isNormalizedLawsuitTitle = (title: string) =>
  title && title.toLowerCase().includes(' x ')

const formatListItemTitle = (title: string, number: string) => {
  const lawsuitNumber = `Processo nº ${number}`
  if (!isNormalizedLawsuitTitle(title)) return lawsuitNumber
  return `${lawsuitNumber} - ${title}`
}

export const formatDescriptionItem = (
  lawsuit: LawsuitNode,
  isGoogleBotAgent: boolean,
  isAdmin: boolean
) => {
  const {
    nature,
    judgeName,
    relatedPeople = [],
    representedPersonLawyers = [],
    lastActivityDate,
    lastActivityText,
  } = lawsuit

  const lastActivity = () => {
    if (isGoogleBotAgent || isAdmin) {
      const lastModifyDate = new Date(lastActivityDate as number)
      const brazilianDate = toBrazilianDate(lastModifyDate.toISOString().substring(0, 10))
      if (lastActivityDate) {
        return `Último andamento: ${brazilianDate} ${formatToRelativeDate(
          lastModifyDate,
          new Date()
        )}, ${lastActivityText}`
      }
    }
    return null
  }

  const getRelatedPeopleText = () => {
    if (relatedPeople && relatedPeople.length > 0) {
      const getPerson = relatedPeople
        .filter((client) => client?.poleA === true || client?.poleB === true)
        .map((p) => `Polo ${p?.poleA ? 'Ativo' : 'Passivo'} - ${p?.name}`)
      return getPerson.join(', ')
    }
    return null
  }

  const getLawyers = () => {
    if (representedPersonLawyers && representedPersonLawyers.length > 0) {
      return representedPersonLawyers.map((l) => `Advogado envolvido: ${l?.name}`)
    }
    return null
  }

  const validText = (array: { text: string; filter: boolean }[]) =>
    array.filter((item) => item.filter === false).map((item) => item.text)

  const relatedPeopleText = getRelatedPeopleText()
  const lawyersText = getLawyers()
  const lastActivityDateText = lastActivity()
  const cnjNumber = lawsuit.cnjNumber
  const sourceText = cnjNumber?.source?.district
  const dataText = cnjNumber?.data?.year
  const justiceText = cnjNumber?.justice?.name
  const courtText = cnjNumber?.court?.acronym
  const parts = [
    {
      text: `Tribunal de Origem: ${courtText} · Comarca ·`,
      filter: !courtText,
    },
    { text: `${sourceText}`, filter: !sourceText },
    { text: `Início do processo: ${dataText}`, filter: !dataText },
    { text: `Natureza: ${nature}`, filter: !nature },
    { text: `Poder Judiciário: ${justiceText}`, filter: !justiceText },
    { text: `Juiz: ${judgeName}`, filter: !judgeName },
    { text: `${relatedPeopleText}`, filter: !relatedPeopleText },
    { text: `${lawyersText}`, filter: !lawyersText },
    { text: `${lastActivityDateText}`, filter: !lastActivityDateText },
  ]
  return validText(parts).join(', ')
}

export const getStructuredData = (props: {
  title: string
  url: string
  description: string
  lawsuits: LawsuitNode[]
  isGoogleBotAgent: boolean
  isAdmin: boolean
}) => {
  const { title, url, description, lawsuits = [], isGoogleBotAgent, isAdmin } = props

  return getStructuredDataSchema({
    webpage: getWebpage({
      name: title,
      canonicalUrl: url,
      description,
      image: { name: 'Consulta Processual Fallback' },
    }),
    breadcrumb: getBreadcrumb({
      name: title,
      canonicalUrl: url,
    }),
    itemList: getItemList(
      url,
      description,
      title,
      lawsuits && lawsuits.length > 0
        ? lawsuits.map((lawsuit: LawsuitNode, index) =>
            getItemListElement(
              index + 1,
              lawsuit.url || '',
              formatListItemTitle(lawsuit.title || '', lawsuit.number || ''),
              formatDescriptionItem(lawsuit, isGoogleBotAgent, isAdmin)
            )
          )
        : []
    ),
  })
}
