import { gql } from '@apollo/client'
import { Button, Card, Container, Detail, Heading } from '@farol-ds/react'
import { capitalizeFullName } from '@jusbrasil-web/lawsuit/shared'
import { LoginTrigger } from '@jusbrasil-web/shared-bifrost'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import { COMPONENT_CLASS, GA4ClickEvent, SELECTED_OPTION } from '@jusbrasil-web/shared-ga4-events'
import classNames from 'classnames'

import { APP_ID } from '../../utils'
import { useContextData } from '../context-provider'
import { PersonEntityCard_LawsuitPartyEntityFragment } from './__generated__/person-entity-card.graphql'
import styles from './person-entity-card.module.scss'
import { buildLawsuitStates } from './util'

export type LawsuitPartyEntity = NonNullable<PersonEntityCard_LawsuitPartyEntityFragment>

export interface PersonEntityCardProps {
  classname?: string
  partyEntity: LawsuitPartyEntity
  fromComponent?: string
  personPosition?: number
  totalEntitiesRelated?: number
  totalCanonicalEntities?: number
}

export function PersonEntityCard(props: PersonEntityCardProps) {
  const { dispatchEvent, dwellTimeListener, isLogged } = useContextData()
  const { sendGA4Event } = useGA4Dispatcher()

  const {
    partyEntity,
    classname,
    fromComponent,
    personPosition,
    totalEntitiesRelated,
    totalCanonicalEntities,
  } = props

  const capitalizedName = capitalizeFullName(partyEntity.identificationName || '')
  const lawsuitStatesArray =
    partyEntity.aggregations?.counterByState?.map((cbs) => cbs?.state || '') || []
  const lawsuitStates = buildLawsuitStates(lawsuitStatesArray)

  const hasAge = !!partyEntity.age
  const hasStates = lawsuitStatesArray.length > 0

  function onClick(): void {
    dispatchEvent('Topic.PersonCardClick', {
      from_component: fromComponent || 'NamePage',
      metadata: {
        dwellTimeMs: Date.now() - dwellTimeListener.startDate,
        personTopicId: partyEntity.entityId,
        personTitle: partyEntity.identificationName,
        nameTitle: capitalizedName,
        lawsuitCount: partyEntity?.aggregations?.totalLawsuits,
        personCountGeneral: totalEntitiesRelated,
        personCount: totalCanonicalEntities,
        personPosition: personPosition,
      },
    })

    sendGA4Event(
      new GA4ClickEvent({
        componentClass: COMPONENT_CLASS.PERSON_CARD,
        componentName: null,
        selectedOption: SELECTED_OPTION.VERIFY_LAWSUITS,
      })
    )

    if (isLogged) window.open(partyEntity.url || '', '_self')
  }

  const entityClickLoginOptions = {
    nextUrl: partyEntity.url || undefined,
    eventData: {
      app_id: APP_ID,
      feature_code: 'unlock_person',
      from_component: fromComponent || 'NamePage',
    },
  }

  return (
    <LoginTrigger options={entityClickLoginOptions} isLogged={isLogged}>
      <Container className={classNames(styles.container, classname)}>
        <div className={styles.container__top}></div>
        <Card className={styles.card} onClick={onClick}>
          <Heading className={styles.card__name} size="sm">
            {capitalizedName}
          </Heading>
          <Detail className={styles.card__info}>
            {hasAge && <span className={styles.card__info__age}>{partyEntity.age} anos</span>}
            {hasAge && hasStates && <span>•</span>}
            {hasStates && <span className={styles.card__info__states}>{lawsuitStates}</span>}
          </Detail>
          <Button
            className={styles.card__button}
            kind="tertiary"
            size="md"
            data-testid="go-to-entity-page"
          >
            Verificar processos
          </Button>
        </Card>
      </Container>
    </LoginTrigger>
  )
}

PersonEntityCard.fragments = {
  lawsuitPartyEntity: gql`
    fragment PersonEntityCard_lawsuitPartyEntity on CRMLawsuitPartyEntity {
      entityId
      age(maskSeniorAge: $maskSeniorAge)
      url
      identificationName(unlockName: true)
      aggregations {
        totalLawsuits
        counterByState {
          state
        }
      }
    }
  `,
}
