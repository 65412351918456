import { useEffect, useState } from 'react'

export function useDebounce<T>(valueToDebounce: T, delay = 500): T {
  const [value, setValue] = useState(valueToDebounce)

  useEffect(() => {
    const handler = setTimeout(() => {
      setValue(valueToDebounce)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [valueToDebounce, delay])

  return value
}
