import type { NameMonitoringModalRootContainerProps } from '@jusbrasil-rc/name-monitoring-modal'
import { useFrameBusClient } from '@jusbrasil-web/shared-frame-bus'
import { useCallback, useEffect, useRef, useState } from 'react'

export const FRAME_BUS_ID = 'name-monitoring-modal'
export const FRAME_BUS_URL = '/web/lawsuit/iframe-name-monitoring-modal'

export const useNameMonitoringModal = () => {
  const [enabled, setEnabled] = useState(false)
  const [ready, setReady] = useState(false)
  const [connected, setConnected] = useState(false)
  const lastPropsRef = useRef<NameMonitoringModalRootContainerProps | null>()

  const frameBusId = FRAME_BUS_ID
  const frameUrl = FRAME_BUS_URL

  const frame = useFrameBusClient<{
    setProps(props: NameMonitoringModalRootContainerProps): void
  }>({
    id: frameBusId,
    src: frameUrl,
    enabled,
    onConnect() {
      setConnected(true)
    },
    onReconnect() {
      frame.close()
    },
    onPopstate() {
      frame.close()
    },
    listeners: {
      componentReady() {
        setReady(true)
      },
      onClose() {
        lastPropsRef.current = null
      },
    },
  })

  const openModal = useCallback(() => {
    if (!enabled) return
    if (!lastPropsRef.current) return
    if (connected) frame.triggerHost('setProps', lastPropsRef.current)
    if (ready) frame.open()
  }, [frame, enabled, connected, ready])

  useEffect(openModal, [openModal])

  return {
    ready,
    loading: enabled && !ready,
    open(props: NameMonitoringModalRootContainerProps) {
      lastPropsRef.current = props
      if (ready) {
        openModal()
      } else if (!enabled) {
        setEnabled(true)
      }
    },
  }
}
