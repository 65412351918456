/**
 * @param url URL string.
 * @returns the section matched from SERP URL.
 */
export function artifactPathFromSerpURL(url: string) {
  const pattern = /\/([^/.]+)\/busca/
  const match = url.match(pattern)
  const section = match ? match[1] : 'general'

  return section
}
