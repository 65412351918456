import { Banner } from '@farol-ds/react'

interface LawsuitListEmptyProps {
  name: string
}

export function LawsuitListEmpty(props: LawsuitListEmptyProps) {
  const { name } = props

  const message = (
    <>
      O Jusbrasil não encontrou processos que contenham o nome <strong>{name}</strong>
    </>
  )

  return (
    <Banner data-testid="lawsuit-list-empty">
      <Banner.Content>
        <Banner.Title>Nenhum processo encontrado</Banner.Title>
        <Banner.Description>{message}</Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
