import { gql } from '@apollo/client'
import { Accordion, Heading } from '@farol-ds/react'
import { LoginTrigger } from '@jusbrasil-web/shared-bifrost'
import { useGA4Dispatcher } from '@jusbrasil-web/shared-ga4'
import {
  COMPONENT_CLASS,
  COMPONENT_NAME,
  GA4ClickEvent,
  GA4ViewItemListRenderProps,
  SELECTED_OPTION,
} from '@jusbrasil-web/shared-ga4-events'
import { handleTriggerExecution } from '@jusbrasil-web/shared-utils-url'
import { useEffect, useState } from 'react'

import { APP_ID } from '../../utils'
import { useContextData } from '../context-provider'
import { LawsuitList } from '../lawsuit-list'
import { LawsuitsAccordion_TopicFragment } from './__generated__/lawsuits-accordion.graphql'

export interface LawsuitsAccordionProps {
  topic: NonNullable<LawsuitsAccordion_TopicFragment>
  displayedLawsuitCount: number
  onAccordionStateChange?: (isOpen: boolean) => void
  ga4ViewItemListEventData?: GA4ViewItemListRenderProps[]
}

export function LawsuitsAccordion(props: LawsuitsAccordionProps) {
  const { topic, displayedLawsuitCount, onAccordionStateChange, ga4ViewItemListEventData } = props
  const { dispatchEvent, isGoogleBotAgent, isLogged } = useContextData()
  const { sendGA4Event } = useGA4Dispatcher()

  const [defaultAccordionValue, setDefaultAccordionValue] = useState<string | undefined>(undefined)
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean | undefined>(false)

  const lawsuitsClickLoginOptions = {
    nextUrl: `${topic.contact?.url}?processos=true`,
    eventData: {
      app_id: APP_ID,
      feature_code: 'unlock_lawsuit_list',
      from_component: 'LawsuitList',
    },
  }

  const onAccordionClick = () => {
    dispatchEvent('Topic.ArtifactFilterClicked', {
      detected_entity_type: 'pessoa',
      artifact: 'NAME_LAWSUITS',
      from_component: 'LawsuitsAccordion',
      metadata: {
        nameTitle: topic.contact?.name,
      },
    })

    sendGA4Event(
      new GA4ClickEvent({
        componentName: COMPONENT_NAME.NAME_RELATED_LAWSUIT_ACCORDION,
        componentClass: COMPONENT_CLASS.LAWSUIT_ACCORDION,
        selectedOption: isAccordionOpen ? SELECTED_OPTION.COLLAPSE : SELECTED_OPTION.EXPAND,
      })
    )
  }

  const unloggedProps: { value?: string } = {}
  if (!isLogged) {
    if (isGoogleBotAgent) {
      unloggedProps.value = 'lawsuitList'
    } else {
      unloggedProps.value = 'closed'
    }
  }

  useEffect(() => {
    handleTriggerExecution({
      triggerName: 'processos',
      onCallback: () => {
        if (isLogged) {
          setDefaultAccordionValue('lawsuitList')
          setIsAccordionOpen(true)
        }
      },
    })
  }, [isLogged])

  return (
    <>
      <LoginTrigger options={lawsuitsClickLoginOptions} isLogged={isLogged}>
        <Accordion
          key={defaultAccordionValue}
          collapsible
          type="single"
          data-testid="lawsuits-accordion"
          defaultValue={defaultAccordionValue}
          onValueChange={(value) => {
            const isLawsuitListVisible = value === 'lawsuitList'
            onAccordionStateChange?.(isLawsuitListVisible)
            setIsAccordionOpen(isLawsuitListVisible)
          }}
          {...unloggedProps}
          asChild
        >
          <Accordion.Item value="lawsuitList">
            <Accordion.Header data-testid="lawsuits-accordion-header" onClick={onAccordionClick}>
              <Accordion.Title>
                <Heading size="lg">Processos por nome</Heading>
                <p>Busque entre todos os processos encontrados que mencionam esse nome.</p>
              </Accordion.Title>
            </Accordion.Header>
            <Accordion.Content data-testid="ac-lawsuit-list">
              <LawsuitList
                topic={topic}
                displayedLawsuitCount={displayedLawsuitCount}
                ga4ViewItemListEventData={ga4ViewItemListEventData}
              />
            </Accordion.Content>
          </Accordion.Item>
        </Accordion>
      </LoginTrigger>
    </>
  )
}

LawsuitsAccordion.fragments = {
  topic: gql`
    fragment LawsuitsAccordion_topic on Topic {
      ...LawsuitList_topic
      contact {
        url
      }
    }
    ${LawsuitList.fragments.topic}
  `,
}
