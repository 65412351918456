import { ParsedUrlQuery } from 'querystring'

import { ServerQueryParams } from '../../../types'

function toBoolean(value: string | string[] | undefined): boolean {
  return value === 'true' || value === '1'
}

function toStringOrNull(value: string | string[] | undefined): string | null {
  return value ? value.toString() : null
}

export function getQueryParams(query: ParsedUrlQuery): ServerQueryParams {
  const isBounce = toBoolean(query?.bounce)
  const utmSource = toStringOrNull(query?.utm_source)
  const queryId = toStringOrNull(query?.query_id)

  return {
    isBounce,
    utmSource,
    queryId,
  }
}
