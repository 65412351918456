/* global window, document */

export default function copyValue<T extends HTMLElement>(
  event: React.MouseEvent<T> | React.KeyboardEvent<T>
) {
  const target = event.target as HTMLElement
  target.contentEditable = 'true'
  const selection = window.getSelection()
  const range = document.createRange()
  range.selectNodeContents(target)
  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }

  document.execCommand('copy')
  target.contentEditable = 'false'
}
