import { RefObject, useEffect, useState } from 'react'

/**
 * @param ref A React ref object that will be used to observe if the element is intersecting with the viewport.
 * @param options An set of options to pass to the IntersectionObserver constructor (for more details on which options are available, check https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver).
 * The default values are:
 *
 * rootMargin - Margin around the root element, default is 0px.
 *
 * threshold - A single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed, default is 1.
 * @param initialIsIntersecting The initial value of the isIntersecting state. You can set this to true if the element is already visible when the component is mounted and above the fold.
 */

export function useOnScreen(
  ref: RefObject<HTMLElement>,
  options?: IntersectionObserverInit,
  initialIsIntersecting = false
): boolean {
  const [isIntersecting, setIntersecting] = useState(initialIsIntersecting)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin: '0px',
        threshold: 1,
        ...options,
      }
    )

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}
