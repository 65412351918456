const FROM_COMPONENTS_MAP: Record<string, string> = {
  '/': 'GeneralSERP',
  '/artigos-noticias/': 'ArtigosNoticiasSERP',
  '/jurisprudencia/': 'JurisSERP',
  '/diarios/': 'JournalSERP',
  '/pecas/': 'PecasSERP',
  '/modelos-pecas/': 'ModelosSERP',
  '/legislacao/': 'LegisSERP',
  '/consulta-processual/': 'ProcessosSERP',
  '/doutrina/': 'DoutrinaSERP',
  '/pessoa/': 'PessoaSERP',
}

export const getFromComponentFromSerp = (referrer: string, fromComponentDefault: string | null) => {
  const regex = /\.br(.*?)busca/
  const matches = referrer?.match(regex) || []
  const artifact = matches[1]?.toLowerCase()
  if (fromComponentDefault === 'NameWidget') return fromComponentDefault
  return FROM_COMPONENTS_MAP[artifact] || fromComponentDefault
}
