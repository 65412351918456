import { gql } from '@apollo/client'
import { Confirm, useToast } from '@farol-ds/react'

import { useContextData } from '../context-provider'
import { StopNameMonitoringConfirm_TopicFragment } from './__generated__/stop-name-monitoring-confirm.graphql'
import { useDeletePublicationMonitoringMutation } from './mutations/delete-publication-monitoring'

interface StopNameMonitoringConfirmProps {
  isOpen: boolean
  topic: NonNullable<StopNameMonitoringConfirm_TopicFragment>
  onClose: () => void
  onSuccessCallback: () => void
  onErrorCallback?: () => void
}

export function StopNameMonitoringConfirm(props: StopNameMonitoringConfirmProps) {
  const { isOpen, topic, onClose, onSuccessCallback, onErrorCallback } = props
  const deleteMonitoringMutation = useDeletePublicationMonitoringMutation(topic.tid)
  const { dispatchEvent } = useContextData()
  const toast = useToast()

  if (!isOpen) {
    return null
  }

  const handleConfirm = () => {
    dispatchEvent('Lawsuit.CancelMonitorNameConfirmed', {
      from_component: 'StopNameMonitoringConfirm',
    })

    deleteMonitoringMutation()
      .then(() => {
        onSuccessCallback()
        toast('Acompanhamento do nome removido com sucesso.')
        onClose()
      })
      .catch((error) => {
        console.error('Error deleting monitoring', error)
        onErrorCallback?.()
        toast('Erro ao remover o acompanhamento do nome.', { type: 'negative' })
      })
  }

  return (
    <Confirm
      title="Deixar de acompanhar o nome?"
      description={`Você não será mais notificado sobre novos processos de ${topic.title} nos Diários Oficiais.`}
      confirmLabel="Deixar de acompanhar"
      cancelLabel="Cancelar"
      onConfirm={handleConfirm}
      onCancel={onClose}
      open
    />
  )
}

StopNameMonitoringConfirm.fragments = {
  topic: gql`
    fragment StopNameMonitoringConfirm_topic on Topic {
      tid
      title
    }
  `,
}
