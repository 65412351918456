import { BifrostGoogleWindowObject, BifrostWindowObject, SignUpModalWindowObject } from './types'

export * from './components'
export * from './types'

declare global {
  interface Window {
    Bifrost?: BifrostWindowObject
    SignUpModal?: SignUpModalWindowObject
    BifrostGoogle?: BifrostGoogleWindowObject
  }
}
