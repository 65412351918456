import { FormatterDivision, FormatterOptions } from '../../types/formatter.type'
import { round } from './round'

const DIVISIONS: FormatterDivision[] = [
  { amount: 60, unit: 'seconds' },
  { amount: 60, unit: 'minutes' },
  { amount: 24, unit: 'hours' },
  { amount: 7, unit: 'days' },
  { amount: 4.34524, unit: 'weeks' },
  { amount: 12, unit: 'months' },
  { amount: Number.POSITIVE_INFINITY, unit: 'years' },
]

const defaultIntlOptions: Intl.RelativeTimeFormatOptions = {
  numeric: 'auto',
  style: 'long',
}

const isValidDate = (date: Date) => date instanceof Date && !isNaN(date.getTime())

/**
 * @description Function that formats the relative date between two dates.
 * @param startDate The start date object
 * @param endDate The end date object
 * @param options The options to format the relative date (optional)
 * @returns The formatted relative date string or null if any date is invalid
 */
export const formatToRelativeDate = (
  startDate: Date,
  endDate: Date,
  options?: FormatterOptions
) => {
  if (!isValidDate(startDate) || !isValidDate(endDate)) {
    return null
  }

  const { minUnit, ...intlOptions } = options || {}

  const formatter = new Intl.RelativeTimeFormat('pt-br', {
    ...defaultIntlOptions,
    ...intlOptions,
  })

  const startDateInMs = startDate.getTime()
  const endDateInMs = endDate.getTime()
  let duration = (startDateInMs - endDateInMs) / 1000

  const minUnitIndex = minUnit ? DIVISIONS.findIndex((division) => division.unit === minUnit) : 0

  for (let i = 0; i < DIVISIONS.length; i++) {
    const { amount, unit } = DIVISIONS[i]
    const isUnitAvailable = i >= minUnitIndex
    if (isUnitAvailable && Math.abs(duration) < amount) {
      const roundedDuration = round(duration, unit)
      return formatter.format(roundedDuration, unit)
    }
    duration /= amount
  }

  return null
}
